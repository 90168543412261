import { AccessSubType, CurrencyCode } from '../../graphql/globalTypes';

export enum CurrencyCodeSetMode {
  DEFAULT = 0,
  AUTOMATIC = 1,
  USER = 2,
}

export type CartItem = {
  bundleName?: string;
  productID?: string;
  productSKU: string;
  count: number;
  redeemableTourIDs?: string[];
  accessSubType?: AccessSubType;
  thumbnailURI?: string;
  guideNumber?: number;
};

export type CartState = {
  items: Array<CartItem>;
  currencyCode: CurrencyCode;
  currencyCodeSetMode: CurrencyCodeSetMode;
};

export const SET_BUNDLE_ITEMS_IN_CART = 'SET_BUNDLE_ITEMS_IN_CART';
export const SET_ITEMS_IN_CART = 'SET_ITEMS_IN_CART';
export const SET_ITEMS_IN_CART_ALLOWED = 'SET_ITEMS_IN_CART_ALLOWED';
export const CLEAR_CART = 'CLEAR_CART';
export const SIGNOUT = 'SIGNOUT';
export const SET_CURRENCY_CODE = 'SET_CURRENCY_CODE';

export type SetCurrencyCodePayload = {
  currencyCode: CurrencyCode;
  currencyCodeSetMode: CurrencyCodeSetMode;
};

export type SetCurrencyCodeAction = {
  payload: SetCurrencyCodePayload;
  type: typeof SET_CURRENCY_CODE;
};

export type SetItemInCartItemPayload = {
  productID: string;
  productSKU: string;
  count: number;
  currencyCode: CurrencyCode;
  guideNumber?: number;
  accessSubType?: AccessSubType;
};

export interface SetItemInCartItemAction {
  type: typeof SET_ITEMS_IN_CART;
  payload: SetItemInCartItemPayload;
}

export type SetBundleItemInCartItemPayload = {
  bundleName: string;
  productSKU: string;
  count: number;
  currencyCode: CurrencyCode;
  redeemableTourIDs: string[];
  accessSubType: AccessSubType;
  thumbnailURI: string;
  guideNumber?: number;
  bundleID?: string;
};

export interface SetBundleItemInCartItemAction {
  type: typeof SET_BUNDLE_ITEMS_IN_CART;
  payload: SetBundleItemInCartItemPayload;
}

export type SetItemInCartItemAllowedPayload = {
  productID: string;
  productSKU: string;
  count: number;
  currencyCode: CurrencyCode;
  accessSubType?: AccessSubType;
  guideNumber?: number;
};

export interface SetItemInCartItemAllowedAction {
  type: typeof SET_ITEMS_IN_CART_ALLOWED;
  payload: SetItemInCartItemAllowedPayload;
}

export type ClearCartPayload = void;

export interface ClearCartAction {
  type: typeof CLEAR_CART;
  payload: ClearCartPayload;
}

export interface SignOutAction {
  type: typeof SIGNOUT;
}

export type CartActionTypes =
  | SetBundleItemInCartItemAction
  | SetItemInCartItemAction
  | SetItemInCartItemAllowedAction
  | ClearCartAction
  | SignOutAction
  | SetCurrencyCodeAction;
