import React from 'react';
import { LoadingScreen } from '../../components/loading/LoadingScreen';

const InternalRoutesView = React.lazy(() => import('./InternalRoutesView'));

export default function InternalRoutes() {
  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <InternalRoutesView />
    </React.Suspense>
  );
}
