import { AccessRestriction, ValidFrom } from '../graphql/globalTypes';
import { format } from 'date-fns';

export const SINGLE_USE_SHORT_TEXT = 'One time use';
export const SINGLE_USE_LONG_TEXT =
  'The code can only be consumed once, however once consumed, it will continue to function until expired. Logins from subsequent devices and installations are denied.';
export const SINGLE_DEVICE_MULTIPLE_USE_SHORT_TEXT = 'One device at a time';
export const SINGLE_DEVICE_MULTIPLE_USE_LONG_TEXT =
  'Until the code has expired, it can only be used in one physical device and app installation at a time. On the same device and installation, the user can still logout and log back in.';
export const MULTIPLE_DEVICES_SHORT_TEXT = 'Multiple devices permitted';
export const MULTIPLE_DEVICES_LONG_TEXT =
  'Until the code has expired, it can be used in any number of devices simultaneously. The user is free to logout and log back in.';

export const TIME_OF_FIRST_USE_SHORT_TEXT = 'First login';
export const TIME_OF_FIRST_USE_LONG_TEXT =
  'Expiry date effective from the date and time of the first login to the end of the validity period.';
export const TIME_OF_CREATION_SHORT_TEXT = 'Now';
export const TIME_OF_CREATION_LONG_TEXT =
  'Expiry date effective from the date and time it was created to the end of the validity period.';

export const getValidFromText = (
  validFrom: ValidFrom,
  createdAt?: any
): string => {
  const defaultValidility = validFrom || ValidFrom.TIME_OF_CREATION;
  createdAt = createdAt || Date.now();

  switch (defaultValidility) {
    case ValidFrom.TIME_OF_FIRST_USE:
      return 'First use';
    case ValidFrom.TIME_OF_CREATION:
      return `${format(new Date(createdAt), 'yyyy MMM dd')}`;
  }
};

export const getValidFromTextFromAccess = (access: {
  validFrom: ValidFrom;
  createdAt: string;
}): string => {
  const defaultValidility = access.validFrom || ValidFrom.TIME_OF_CREATION;
  switch (defaultValidility) {
    case ValidFrom.TIME_OF_FIRST_USE:
      return 'First use';
    case ValidFrom.TIME_OF_CREATION:
      return `${format(new Date(access.createdAt), 'yyyy MMM dd')}`;
  }
};

export const getAccessRestrictionText = (
  accessRestriction: AccessRestriction
): string => {
  const defaultValidility =
    accessRestriction || AccessRestriction.SINGLE_DEVICE_MULTIPLE_USE;
  switch (defaultValidility) {
    case AccessRestriction.SINGLE_USE:
      return `${SINGLE_USE_SHORT_TEXT}.`;
    case AccessRestriction.SINGLE_DEVICE_MULTIPLE_USE:
      return `${SINGLE_DEVICE_MULTIPLE_USE_SHORT_TEXT}.`;
    case AccessRestriction.MULTIPLE_DEVICES:
      return `${MULTIPLE_DEVICES_SHORT_TEXT}.`;
  }
};

export const getAccessRestrictionTextFromAccess = (access: {
  accessRestriction: AccessRestriction;
}): string => {
  const defaultValidility =
    access.accessRestriction || AccessRestriction.SINGLE_DEVICE_MULTIPLE_USE;
  switch (defaultValidility) {
    case AccessRestriction.SINGLE_USE:
      return `${SINGLE_USE_SHORT_TEXT}.`;
    case AccessRestriction.SINGLE_DEVICE_MULTIPLE_USE:
      return `${SINGLE_DEVICE_MULTIPLE_USE_SHORT_TEXT}.`;
    case AccessRestriction.MULTIPLE_DEVICES:
      return `${MULTIPLE_DEVICES_SHORT_TEXT}.`;
  }
};

export const getAccessRestrictionLegendTextFromAccess = (access: {
  accessRestriction: AccessRestriction;
}): string => {
  const defaultValidility =
    access.accessRestriction || AccessRestriction.SINGLE_DEVICE_MULTIPLE_USE;

  switch (defaultValidility) {
    case AccessRestriction.SINGLE_USE:
      return `${SINGLE_USE_SHORT_TEXT}: ${SINGLE_USE_LONG_TEXT}`;
    case AccessRestriction.SINGLE_DEVICE_MULTIPLE_USE:
      return `${SINGLE_DEVICE_MULTIPLE_USE_SHORT_TEXT}: ${SINGLE_DEVICE_MULTIPLE_USE_LONG_TEXT}`;
    case AccessRestriction.MULTIPLE_DEVICES:
      return `${MULTIPLE_DEVICES_SHORT_TEXT}: ${MULTIPLE_DEVICES_LONG_TEXT}`;
  }
};

export function getCamelCaseString(str: string) {
  const tempStr = str.toLowerCase();
  return tempStr[0].toUpperCase() + tempStr.slice(1);
}
