import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { isInternal } from '../store/auth/selectors';
import { AppRoutes } from './AppRoutes';
import { BusinessTheme, InternalTheme } from './AppTheme';
import { AppGlobalStyle } from './components/AppGlobalStyle';
import { StateListener } from './components/StateListener';
import { Modals } from './Modals/Modals';
import { SessionEventHandler } from './SessionEventHandler';

export const App = () => {
  const internal = useSelector(isInternal);
  const theme = internal ? InternalTheme : BusinessTheme;

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <CssBaseline />
        <AppGlobalStyle />
        <SessionEventHandler />
        <Route component={AppRoutes} />
        <ToastContainer />
        <StateListener />
        <Modals />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};
