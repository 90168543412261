import { ROLES } from '../../config/enums';

export const ADMIN_ONLY = [ROLES.ADMIN];
export const ADMIN_AND_SALES = [ROLES.ADMIN, ROLES.SALES];
export const INTERNAL = [ROLES.ADMIN, ROLES.SALES, ROLES.STAFF];

export enum ROUTES {
  INDEX = '/',

  DEV = '/dev',

  DASHBOARD = '/dashboard',

  ANALYTICS = '/analytics',
  ANALYTICS_VIEWPOINTS_USAGE = '/analytics/viewpoints_usage',
  ANALYTICS_LANGUAGE_USAGE = '/analytics/language_usage',
  ANALYTICS_APP_USAGE = '/analytics/app_usage',
  ANALYTICS_REPORTS = '/analytics/reports',
  RAW_SESSION_DATA = '/analytics/session_data/:sessionID',
  SESSION_REPORTS = '/analytics/session_reports',
  AW_USER_METRICS = '/analytics/aw_user_metrics',

  ACCESSES = '/accesses',
  ACCESS = '/accesses/:accessID',
  ACTIVATIONS = '/activations',

  ACCOUNT = '/account',
  CHANGE_PASSWORD = '/account/change_password',
  EDIT_PROFILE = '/account/edit',

  CLIENTS = '/clients',
  CLIENT = '/clients/:clientID',
  CLIENT_CREATE = '/clients/create',
  CLIENT_EDIT = '/clients/:clientID/edit',
  CLIENT_ACCESS_CREATE = '/clients/:clientID/accesses/create',
  CLIENT_ACCESS_DEEPLINK_CREATE = '/clients/:clientID/accesses/:accessID/content-deeplink',
  CLIENT_ACCESS = '/clients/:clientID/accesses/:accessID',
  CLIENT_AFFILIATE_CODE_CREATE = '/clients/:clientID/affiliate_codes/create',
  CLIENT_AFFILIATE_CODE_EDIT = '/clients/:clientID/affiliate_codes/:affiliateCodeID/edit',
  CLIENT_AFFILIATE_CODE_DEEPLINK_CREATE = '/clients/:clientID/affiliate_codes/:affiliateCodeID/content-deeplink',
  CLIENT_AFFILIATE_CODE = '/clients/:clientID/affiliate_codes/:affiliateCodeID',
  CLIENT_USERS = '/clients/:clientID/users',
  CLIENTS_USER = '/clients/:clientID/users/:userID',
  CLIENT_USER_EDIT = '/clients/:clientID/users/:userID/edit',
  CLIENT_INVOICE = '/clients/:clientID/invoices/:invoiceID',

  ACTIVATION_REPORT = '/reports/activations',

  ALL_MUSIC = '/music',
  MUSIC_CREATE = '/music/create',
  MUSIC_TRACK = '/music/:musicID',
  MUSIC_TRACK_EDIT = '/music/:musicID/edit',

  PLACES = '/places',
  PLACES_CREATE = '/places/create',
  PLACE = '/places/:placeID',
  PLACE_EDIT = '/places/:placeID/edit',

  STOPS = '/stops',
  STOPS_CREATE = '/stops/create',
  STOP = '/stops/:stopID',
  STOP_EDIT = '/stops/:stopID/edit',

  DISCOVERIES = '/discoveries',
  DISCOVERY_CREATE = '/discoveries/create',
  DISCOVERY = '/discoveries/:discoveryID',
  DISCOVERY_EDIT = '/discoveries/:discoveryID/edit',

  ARTEFACTS = '/artefacts',
  ARTEFACT_CREATE = '/artefacts/create',
  ARTEFACT = '/artefacts/:artefactID',
  ARTEFACT_EDIT = '/artefacts/:artefactID/edit',

  ALL_GUIDES = '/guides',
  GUIDES_CREATE = '/guides/create',
  GUIDE = '/guides/:guideID',
  GUIDE_EDIT = '/guides/:guideID/edit',

  VIEWPOINTS = '/viewpoints',
  VIEWPOINT_CREATE = '/viewpoints/create',
  VIEWPOINT = '/viewpoints/:viewpointID',
  VIEWPOINT_EDIT = '/viewpoints/:viewpointID/edit',

  VIEWPOINT_IMAGE_CREATE = '/viewpoints/:viewpointID/images/create',
  VIEWPOINT_IMAGE_EDIT = '/viewpoints/:viewpointID/images/:imageID/edit',
  VIEWPOINT_IMAGES_EDIT = '/viewpoints/:viewpointID/images/edit',

  VIEWPOINT_NARRATION_CREATE = '/viewpoints/:viewpointID/narrations/create',
  VIEWPOINT_NARRATION_EDIT = '/viewpoints/:viewpointID/narrations/:narrationID/edit',
  VIEWPOINT_NARRATIONS_EDIT = '/viewpoints/:viewpointID/narrations/edit',
  STOP_NARRATION_CREATE = '/stops/:stopID/narrations/create',
  STOP_NARRATION_EDIT = '/stops/:stopID/narrations/:narrationID/edit',
  STOP_NARRATIONS_EDIT = '/stops/:stopID/narrations/edit',
  DISCOVERY_NARRATION_CREATE = '/discoveries/:discoveryID/narrations/create',
  DISCOVERY_NARRATION_EDIT = '/discoveries/:discoveryID/narrations/:narrationID/edit',
  DISCOVERY_NARRATIONS_EDIT = '/discoveries/:discoveryID/narrations/edit',

  SITES = '/sites',
  SITE_CREATE = '/sites/create',
  SITE = '/sites/:siteID',
  SITE_EDIT = '/sites/:siteID/edit',

  CURATED_PLAYLISTS = '/curated_playlists/:label',
  CURATED_PLAYLISTS_CREATE = '/curated_playlists/:label/create',
  CURATED_PLAYLIST = '/curated_playlists/:label/:playlistID',
  CURATED_PLAYLIST_VIEWPOINT = '/curated_playlists/:label/:playlistID/:viewpointID',
  CURATED_PLAYLIST_EDIT = '/curated_playlists/:label/:playlistID/edit',
  CURATED_PLAYLIST_INTRO_NARRATIONS_EDIT = '/curated_playlists/:label/:playlistID/narrations/edit',
  CURATED_PLAYLIST_INTRO_NARRATION_CREATE = '/curated_playlists/:label/:playlistID/narrations/create',
  CURATED_PLAYLIST_INTRO_NARRATION_EDIT = '/curated_playlists/:label/:playlistID/narrations/:narrationID/edit',

  PRINTS = '/prints',
  PRINTS_CREATE = '/prints/create',
  PRINT_TEMPLATES = '/prints/templates',
  PRINT_INVOICE = '/print_invoice',

  QR_CODES = '/qrcodes',

  USERS = '/users',
  USER_CREATE = '/users/create',
  USER = '/users/:userID',
  USER_EDIT = '/users/:userID/edit',
  USER_PLAYLIST = '/users/:userID/playlists/:playlistID',

  PURCHASES = '/aw/purchases',
  AK = '/aw/ak',
  AFFFILIATE_PURCHASES = '/aw/affiliate_purchases',
  REDEEM_CODE_PURCHASES = '/aw/redeem_code_purchases',
  AW_REGISTERED_USERS = '/aw/users',
  AW_USER_CONVERSIONS = '/aw/users/conversions',
  AW_USAGE_STATS = '/aw/users/usage',

  PROMO_CODES = '/aw/promocodes',
  // PROMO_CODE = '/aw/promocodes/:id',
  PROMO_CODE_CREATE = '/aw/promocodes/create',
  PROMO_CODE_EDIT = '/aw/promocodes/:id/edit',

  INVOICES = '/invoices',
  INVOICE = '/invoices/:invoiceID',

  UTILS = '/utils',
  UTILS_GRAPHIQL = '/utils/graphiql',
  UTILS_FILE_UPLOADER = '/utils/file_uploader',
  UTILS_PROJECTION_UPLOADER = '/utils/projection_uploader',
  UTILS_ANALYSE_PURCHASE = '/utils/analyse_purchase',
  UTILS_ANALYSE_REDEEM_CODE = '/utils/analyse_redeem_code',
  UTILS_CREATE_INTERNAL_ACCOUNT = '/utils/create_internal_account',
  UTILS_UPDATE_API_ENDPOINT = '/utils/update_api_endpoint',

  APPS = '/apps',
  APP_CREATE = '/apps/create',
  APP = '/apps/:appID',
  APP_EDIT = '/apps/:appID/edit',

  REVIEW = '/reviews/:reviewID',
  REVIEWS = '/reviews',
}

export const ROUTE_CONFIG = {
  INDEX: {
    path: '/',
  },
  DEV: {
    path: ROUTES.DEV,
    authorisedRoles: ADMIN_ONLY,
  },
  DASHBOARD: {
    path: ROUTES.DASHBOARD,
    authorisedRoles: INTERNAL,
  },

  ACCESS: {
    path: ROUTES.ACCESS,
    authorisedRoles: ADMIN_AND_SALES,
  },

  ACCOUNT: {
    path: ROUTES.ACCOUNT,
    authorisedRoles: INTERNAL,
  },
  EDIT_PROFILE: {
    path: ROUTES.EDIT_PROFILE,
    authorisedRoles: INTERNAL,
  },
  CHANGE_PASSWORD: {
    path: ROUTES.CHANGE_PASSWORD,
    authorisedRoles: INTERNAL,
  },

  CLIENTS: {
    path: ROUTES.CLIENTS,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CLIENT: {
    path: ROUTES.CLIENT,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CLIENT_CREATE: {
    path: ROUTES.CLIENT_CREATE,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CLIENT_AFFILIATE_CODE_CREATE: {
    path: ROUTES.CLIENT_AFFILIATE_CODE_CREATE,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CLIENT_AFFILIATE_CODE_EDIT: {
    path: ROUTES.CLIENT_AFFILIATE_CODE_EDIT,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CLIENT_EDIT: {
    path: ROUTES.CLIENT_EDIT,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CLIENT_ACCESS_CREATE: {
    path: ROUTES.CLIENT_ACCESS_CREATE,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CLIENT_ACCESS_DEEPLINK_CREATE: {
    path: ROUTES.CLIENT_ACCESS_DEEPLINK_CREATE,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CLIENT_ACCESS: {
    path: ROUTES.CLIENT_ACCESS,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CLIENT_AFFILIATE_CODE: {
    path: ROUTES.CLIENT_AFFILIATE_CODE,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CLIENT_INVOICE: {
    path: ROUTES.CLIENT_INVOICE,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CLIENT_USERS: {
    path: ROUTES.CLIENT_USERS,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CLIENTS_USER: {
    path: ROUTES.CLIENTS_USER,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CLIENTS_USER_EDIT: {
    path: ROUTES.CLIENT_USER_EDIT,
    authorisedRoles: ADMIN_AND_SALES,
  },

  ACTIVATION_REPORT: {
    path: ROUTES.ACTIVATION_REPORT,
    authorisedRoles: ADMIN_AND_SALES,
  },

  INVOICES: {
    path: ROUTES.INVOICES,
    authorisedRoles: ADMIN_AND_SALES,
  },
  INVOICE: {
    path: ROUTES.INVOICE,
    authorisedRoles: ADMIN_AND_SALES,
  },

  PRINT_INVOICE: {
    path: ROUTES.PRINT_INVOICE,
    authorisedRoles: ADMIN_AND_SALES,
  },

  ANALYTICS: {
    path: ROUTES.ANALYTICS,
    authorisedRoles: INTERNAL,
  },

  AW_USER_METRICS: {
    path: ROUTES.AW_USER_METRICS,
    authorisedRoles: INTERNAL,
  },

  ALL_MUSIC: {
    path: ROUTES.ALL_MUSIC,
    authorisedRoles: ADMIN_AND_SALES,
  },
  MUSIC_CREATE: {
    path: ROUTES.MUSIC_CREATE,
    authorisedRoles: ADMIN_ONLY,
  },
  MUSIC: {
    path: ROUTES.MUSIC_TRACK,
    authorisedRoles: ADMIN_AND_SALES,
  },
  MUSIC_EDIT: {
    path: ROUTES.MUSIC_TRACK_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },

  ALL_GUIDES: {
    path: ROUTES.ALL_GUIDES,
    authorisedRoles: ADMIN_AND_SALES,
  },
  PLACES: {
    path: ROUTES.PLACES,
    authorisedRoles: ADMIN_AND_SALES,
  },
  STOPS: {
    path: ROUTES.STOPS,
    authorisedRoles: ADMIN_AND_SALES,
  },
  DISCOVERIES: {
    path: ROUTES.DISCOVERIES,
    authorisedRoles: ADMIN_AND_SALES,
  },
  ARTEFACTS: {
    path: ROUTES.ARTEFACTS,
    authorisedRoles: ADMIN_AND_SALES,
  },
  VIEWPOINTS: {
    path: ROUTES.VIEWPOINTS,
    authorisedRoles: ADMIN_AND_SALES,
  },
  VIEWPOINT_CREATE: {
    path: ROUTES.VIEWPOINT_CREATE,
    authorisedRoles: ADMIN_ONLY,
  },
  VIEWPOINT: {
    path: ROUTES.VIEWPOINT,
    authorisedRoles: ADMIN_AND_SALES,
  },
  VIEWPOINT_EDIT: {
    path: ROUTES.VIEWPOINT_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },

  VIEWPOINT_IMAGE_CREATE: {
    path: ROUTES.VIEWPOINT_IMAGE_CREATE,
    authorisedRoles: ADMIN_ONLY,
  },
  VIEWPOINT_IMAGE_EDIT: {
    path: ROUTES.VIEWPOINT_IMAGE_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },
  VIEWPOINT_IMAGES_EDIT: {
    path: ROUTES.VIEWPOINT_IMAGES_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },

  VIEWPOINT_NARRATION_CREATE: {
    path: ROUTES.VIEWPOINT_NARRATION_CREATE,
    authorisedRoles: ADMIN_ONLY,
  },
  VIEWPOINT_NARRATION_EDIT: {
    path: ROUTES.VIEWPOINT_NARRATION_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },
  VIEWPOINT_NARRATIONS_EDIT: {
    path: ROUTES.VIEWPOINT_NARRATIONS_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },

  STOP_NARRATION_CREATE: {
    path: ROUTES.STOP_NARRATION_CREATE,
    authorisedRoles: ADMIN_ONLY,
  },
  STOP_NARRATION_EDIT: {
    path: ROUTES.STOP_NARRATION_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },
  STOP_NARRATIONS_EDIT: {
    path: ROUTES.STOP_NARRATIONS_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },

  DISCOVERY_NARRATION_CREATE: {
    path: ROUTES.DISCOVERY_NARRATION_CREATE,
    authorisedRoles: ADMIN_ONLY,
  },
  DISCOVERY_NARRATION_EDIT: {
    path: ROUTES.DISCOVERY_NARRATION_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },
  DISCOVERY_NARRATIONS_EDIT: {
    path: ROUTES.DISCOVERY_NARRATIONS_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },

  SITES: {
    path: ROUTES.SITES,
    authorisedRoles: ADMIN_AND_SALES,
  },
  SITE_CREATE: {
    path: ROUTES.SITE_CREATE,
    authorisedRoles: ADMIN_ONLY,
  },
  SITE: {
    path: ROUTES.SITE,
    authorisedRoles: ADMIN_AND_SALES,
  },
  SITE_EDIT: {
    path: ROUTES.SITE_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },

  CURATED_PLAYLISTS: {
    path: ROUTES.CURATED_PLAYLISTS,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CURATED_PLAYLISTS_CREATE: {
    path: ROUTES.CURATED_PLAYLISTS_CREATE,
    authorisedRoles: ADMIN_ONLY,
  },
  CURATED_PLAYLIST_EDIT: {
    path: ROUTES.CURATED_PLAYLIST_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },
  CURATED_PLAYLIST: {
    path: ROUTES.CURATED_PLAYLIST,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CURATED_PLAYLIST_VIEWPOINT: {
    path: ROUTES.CURATED_PLAYLIST_VIEWPOINT,
    authorisedRoles: ADMIN_AND_SALES,
  },
  CURATED_PLAYLIST_INTRO_NARRATIONS_EDIT: {
    path: ROUTES.CURATED_PLAYLIST_INTRO_NARRATIONS_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },
  CURATED_PLAYLIST_INTRO_NARRATION_EDIT: {
    path: ROUTES.CURATED_PLAYLIST_INTRO_NARRATION_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },
  CURATED_PLAYLIST_INTRO_NARRATION_CREATE: {
    path: ROUTES.CURATED_PLAYLIST_INTRO_NARRATION_CREATE,
    authorisedRoles: ADMIN_ONLY,
  },

  PRINTS_CREATE: {
    path: ROUTES.PRINTS_CREATE,
    authorisedRoles: ADMIN_AND_SALES,
  },

  USERS: {
    path: ROUTES.USERS,
    authorisedRoles: ADMIN_ONLY,
  },
  USER_CREATE: {
    path: ROUTES.USER_CREATE,
    authorisedRoles: ADMIN_ONLY,
  },
  USER: {
    path: ROUTES.USER,
    authorisedRoles: ADMIN_ONLY,
  },
  USER_EDIT: {
    path: ROUTES.USER_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },
  USER_PLAYLIST: {
    path: ROUTES.USER_PLAYLIST,
    authorisedRoles: ADMIN_ONLY,
  },

  APPS: {
    path: ROUTES.APPS,
    authorisedRoles: ADMIN_ONLY,
  },
  APP_CREATE: {
    path: ROUTES.APP_CREATE,
    authorisedRoles: ADMIN_ONLY,
  },
  APP: {
    path: ROUTES.APP,
    authorisedRoles: ADMIN_ONLY,
  },
  APP_EDIT: {
    path: ROUTES.APP_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },

  AK: {
    path: ROUTES.AK,
    authorisedRoles: ADMIN_ONLY,
  },
  PURCHASES: {
    path: ROUTES.PURCHASES,
    authorisedRoles: ADMIN_ONLY,
  },

  PROMO_CODES: {
    path: ROUTES.PROMO_CODES,
    authorisedRoles: ADMIN_ONLY,
  },
  // PROMO_CODE: {
  //   path: ROUTES.PROMO_CODE,
  //   authorisedRoles: ADMIN_ONLY,
  // },
  PROMO_CODE_CREATE: {
    path: ROUTES.PROMO_CODE_CREATE,
    authorisedRoles: ADMIN_ONLY,
  },
  PROMO_CODE_EDIT: {
    path: ROUTES.PROMO_CODE_EDIT,
    authorisedRoles: ADMIN_ONLY,
  },

  AFFFILIATE_PURCHASES: {
    path: ROUTES.AFFFILIATE_PURCHASES,
    authorisedRoles: ADMIN_ONLY,
  },

  REDEEM_CODE_PURCHASES: {
    path: ROUTES.REDEEM_CODE_PURCHASES,
    authorisedRoles: ADMIN_ONLY,
  },

  AW_REGISTERED_USERS: {
    path: ROUTES.AW_REGISTERED_USERS,
    authorisedRoles: ADMIN_ONLY,
  },

  AW_USER_CONVERSIONS: {
    path: ROUTES.AW_USER_CONVERSIONS,
    authorisedRoles: ADMIN_ONLY,
  },

  AW_USAGE_STATS: {
    path: ROUTES.AW_USAGE_STATS,
    authorisedRoles: ADMIN_ONLY,
  },

  UTILS: {
    path: ROUTES.UTILS,
    authorisedRoles: ADMIN_ONLY,
  },

  UTILS_GRAPHIQL: {
    path: ROUTES.UTILS_GRAPHIQL,
    authorisedRoles: ADMIN_ONLY,
  },

  UTILS_FILE_UPLOADER: {
    path: ROUTES.UTILS_FILE_UPLOADER,
    authorisedRoles: ADMIN_ONLY,
  },

  UTILS_PROJECITON_UPLOADER: {
    path: ROUTES.UTILS_PROJECTION_UPLOADER,
    authorisedRoles: ADMIN_ONLY,
  },

  UTILS_UPDATE_API_ENDPOINT: {
    path: ROUTES.UTILS_UPDATE_API_ENDPOINT,
    authorisedRoles: ADMIN_ONLY,
  },

  UTILS_ANALYSE_PURCHASE: {
    path: ROUTES.UTILS_ANALYSE_PURCHASE,
    authorisedRoles: ADMIN_ONLY,
  },

  UTILS_ANALYSE_REDEEM_CODE: {
    path: ROUTES.UTILS_ANALYSE_REDEEM_CODE,
    authorisedRoles: ADMIN_ONLY,
  },

  UTILS_CREATE_INTERNAL_ACCOUNT: {
    path: ROUTES.UTILS_CREATE_INTERNAL_ACCOUNT,
    authorisedRoles: ADMIN_ONLY,
  },

  REVIEWS: {
    path: ROUTES.REVIEWS,
    authorisedRoles: ADMIN_ONLY,
  },

  REVIEW: {
    path: ROUTES.REVIEW,
    authorisedRoles: ADMIN_ONLY,
  },
};

export const routeUser = (userID: string) =>
  ROUTES.USER.replace(':userID', userID);

export const routeEditUser = (userID: string, clientID?: string) => {
  if (clientID) {
    return ROUTES.CLIENT_USER_EDIT.replace(':clientID', clientID).replace(
      ':userID',
      userID
    );
  }

  return ROUTES.USER_EDIT.replace(':userID', userID);
};

export const routeCuratedPlaylistsByLabel = (label: string) =>
  `${ROUTES.CURATED_PLAYLISTS.replace(':label', label)}`;

export const routeCuratedPlaylistByID = (label: string, playlistID: string) =>
  ROUTES.CURATED_PLAYLIST.replace(':label', label).replace(
    ':playlistID',
    playlistID
  );

export const routePlaceByID = (placeID: string) =>
  ROUTES.PLACE.replace(':placeID', placeID);
export const routeStopByID = (stopID: string) =>
  ROUTES.STOP.replace(':stopID', stopID);
export const routeDiscoveryByID = (discoveryID: string) =>
  ROUTES.DISCOVERY.replace(':discoveryID', discoveryID);
export const routeArtefactByID = (artefactID: string) =>
  ROUTES.ARTEFACT.replace(':artefactID', artefactID);
export const routeClient = (clientID: string) =>
  ROUTES.CLIENT.replace(':clientID', clientID);

export const routeSiteID = (siteID: string) =>
  ROUTES.SITE.replace(':siteID', siteID);

export const routeSiteEdit = (siteID: string) =>
  ROUTES.SITE_EDIT.replace(':siteID', siteID);

export const routeCuratedPlaylistEdit = (label: string, playlistID: string) =>
  ROUTES.CURATED_PLAYLIST_EDIT.replace(':label', label).replace(
    ':playlistID',
    playlistID
  );

export const routeViewpointByID = (viewpointID: string) =>
  ROUTES.VIEWPOINT.replace(':viewpointID', viewpointID);
export const routeViewpointEdit = (viewpointID: string) =>
  ROUTES.VIEWPOINT_EDIT.replace(':viewpointID', viewpointID);
export const routePlaceEdit = (placeID: string) =>
  ROUTES.PLACE_EDIT.replace(':placeID', placeID);
export const routeStopEdit = (stopID: string) =>
  ROUTES.STOP_EDIT.replace(':stopID', stopID);
export const routeDiscoveryEdit = (discoveryID: string) =>
  ROUTES.DISCOVERY_EDIT.replace(':discoveryID', discoveryID);
export const routeArtefactEdit = (artefact: string) =>
  ROUTES.ARTEFACT_EDIT.replace(':artefact', artefact);

export const routeViewpointImageCreate = (viewpointID: string) =>
  ROUTES.VIEWPOINT_IMAGE_CREATE.replace(':viewpointID', viewpointID);

export const routeViewpointImageEdit = (viewpointID: string, imageID: string) =>
  ROUTES.VIEWPOINT_IMAGE_EDIT.replace(':viewpointID', viewpointID).replace(
    ':imageID',
    imageID
  );

export const routeViewpointImagesEdit = (viewpointID: string) =>
  ROUTES.VIEWPOINT_IMAGES_EDIT.replace(':viewpointID', viewpointID);

export const routeViewpointImagesTab = (viewpointID: string) =>
  `${ROUTES.VIEWPOINT.replace(':viewpointID', viewpointID)}?tab=images`;

export const routeViewpointNarrationCreate = (viewpointID: string) =>
  ROUTES.VIEWPOINT_NARRATION_CREATE.replace(':viewpointID', viewpointID);
export const routeStopNarrationCreate = (stopID: string) =>
  ROUTES.STOP_NARRATION_CREATE.replace(':stopID', stopID);
export const routeDiscoveryNarrationCreate = (discoveryID: string) =>
  ROUTES.DISCOVERY_NARRATION_CREATE.replace(':discoveryID', discoveryID);

export const routeViewpointNarrationEdit = (
  viewpointID: string,
  narrationID: string
) =>
  ROUTES.VIEWPOINT_NARRATION_EDIT.replace(':viewpointID', viewpointID).replace(
    ':narrationID',
    narrationID
  );

export const routeStopNarrationEdit = (stopID: string, narrationID: string) =>
  ROUTES.STOP_NARRATION_EDIT.replace(':stopID', stopID).replace(
    ':narrationID',
    narrationID
  );

export const routeDiscoveryNarrationEdit = (
  discoveryID: string,
  narrationID: string
) =>
  ROUTES.DISCOVERY_NARRATION_EDIT.replace(':discoveryID', discoveryID).replace(
    ':narrationID',
    narrationID
  );

export const routeViewpointNarrationsEdit = (viewpointID: string) =>
  ROUTES.VIEWPOINT_NARRATIONS_EDIT.replace(':viewpointID', viewpointID);
export const routeStopNarrationsEdit = (stopID: string) =>
  ROUTES.STOP_NARRATIONS_EDIT.replace(':stopID', stopID);
export const routeDiscoveryNarrationsEdit = (discoveryID: string) =>
  ROUTES.DISCOVERY_NARRATIONS_EDIT.replace(':discoveryID', discoveryID);

export const routeViewpointNarrationsTab = (viewpointID: string) =>
  `${ROUTES.VIEWPOINT.replace(':viewpointID', viewpointID)}?tab=narrations`;
export const routeStopNarrationsTab = (stopID: string) =>
  `${ROUTES.STOP.replace(':stopID', stopID)}?tab=narrations`;
export const routeDiscoveryNarrationsTab = (discoveryID: string) =>
  `${ROUTES.DISCOVERY.replace(':discoveryID', discoveryID)}?tab=narrations`;

export const routeCuratedPlaylistIntroNarrationsTab = (
  label: string,
  playlistID: string
) =>
  `${ROUTES.CURATED_PLAYLIST.replace(':label', label).replace(
    ':playlistID',
    playlistID
  )}?tab=introNarrations`;

export const routeCuratedPlaylistIntroNarrationEdit = (
  label: string,
  playlistID: string,
  narrationID: string
) =>
  ROUTES.CURATED_PLAYLIST_INTRO_NARRATION_EDIT.replace(':label', label)
    .replace(':playlistID', playlistID)
    .replace(':narrationID', narrationID);

export const routeCuratedPlaylistIntroNarrationCreate = (
  label: string,
  playlistID: string
) =>
  ROUTES.CURATED_PLAYLIST_INTRO_NARRATION_CREATE.replace(
    ':label',
    label
  ).replace(':playlistID', playlistID);

export const routeClientDetailsTab = (clientID: string) =>
  `${ROUTES.CLIENT.replace(':clientID', clientID)}?tab=client_details`;

export const routeClientUsersTab = (clientID: string) =>
  `${ROUTES.CLIENT.replace(':clientID', clientID)}?tab=users`;

export const routeClientAccessCodesTab = (clientID: string) =>
  `${ROUTES.CLIENT.replace(':clientID', clientID)}?tab=access_codes`;

export const routeClientAffiliateCodesTab = (clientID: string) =>
  `${ROUTES.CLIENT.replace(':clientID', clientID)}?tab=affiliate_codes`;

export const routeClientRedeemCodesTab = (clientID: string) =>
  `${ROUTES.CLIENT.replace(':clientID', clientID)}?tab=redeem_codes`;

export const routeClientInvoicesTab = (clientID: string) =>
  `${ROUTES.CLIENT.replace(':clientID', clientID)}?tab=invoices`;

export const routeClientAccessByID = (accessID: string, clientID: string) =>
  ROUTES.CLIENT_ACCESS.replace(':clientID', clientID).replace(
    ':accessID',
    accessID
  );

export const routeClientAffiliateCodeByID = (
  affiliateCodeID: string,
  clientID: string
) =>
  ROUTES.CLIENT_AFFILIATE_CODE.replace(':clientID', clientID).replace(
    ':affiliateCodeID',
    affiliateCodeID
  );

export const routeCreateClientAccess = (clientID: string) =>
  ROUTES.CLIENT_ACCESS_CREATE.replace(':clientID', clientID);

export const routeCreateClientAffiliateCode = (clientID: string) =>
  ROUTES.CLIENT_AFFILIATE_CODE_CREATE.replace(':clientID', clientID);

export const routeEditClientAffiliateCode = (
  clientID: string,
  affiliateCodeID: string
) =>
  ROUTES.CLIENT_AFFILIATE_CODE_EDIT.replace(':clientID', clientID).replace(
    ':affiliateCodeID',
    affiliateCodeID
  );

export const routeCreateAccessDeepLink = (accessID: string, clientID: string) =>
  ROUTES.CLIENT_AFFILIATE_CODE_DEEPLINK_CREATE.replace(
    ':clientID',
    clientID
  ).replace(':accessID', accessID);

export const routeCreateCuratedPlaylist = (label: string) =>
  ROUTES.CURATED_PLAYLISTS_CREATE.replace(':label', label);

export const routeEditClient = (clientID: string) =>
  ROUTES.CLIENT_EDIT.replace(':clientID', clientID);

export const routeClientUsers = (clientID: string) =>
  ROUTES.CLIENT_USERS.replace(':clientID', clientID);

export const routeClientUser = (clientID: string, userID: string) =>
  ROUTES.CLIENTS_USER.replace(':clientID', clientID).replace(':userID', userID);

export const routeClientInvoice = (clientID: string, invoiceID: string) =>
  ROUTES.CLIENT_INVOICE.replace(':clientID', clientID).replace(
    ':invoiceID',
    invoiceID
  );

export const routeUserPlaylist = (userID: string, playlistID: string) =>
  `/users/${userID}/playlists/${playlistID}`;

export const routeUserPlaylistTab = (userID: string) =>
  `/users/${userID}?tab=playlists`;

export const routeMusicByID = (id: string) => `${ROUTES.ALL_MUSIC}/${id}`;
export const routePlacesByID = (id: string) => `${ROUTES.PLACES}/${id}`;
export const routeStopsByID = (id: string) => `${ROUTES.STOPS}/${id}`;
export const routeDiscoveriesByID = (id: string) =>
  `${ROUTES.DISCOVERIES}/${id}`;

export const routeGuidesByID = (id: string) => `${ROUTES.ALL_GUIDES}/${id}`;

export const routeCuratedPlaylistViewpoint = (
  label: string,
  playlistID: string,
  viewpointID: string
) =>
  ROUTE_CONFIG.CURATED_PLAYLIST_VIEWPOINT.path
    .replace(':label', label)
    .replace(':playlistID', playlistID)
    .replace(':viewpointID', viewpointID);

export const routeAppByAppID = (appID: string) =>
  ROUTES.APP.replace(':appID', appID);

export const routeAppEdit = (appID: string) =>
  ROUTES.APP_EDIT.replace(':appID', appID);

export const routeAppCreate = () => ROUTES.APP_CREATE;

export const routeByClientID = (clientID: string) =>
  ROUTES.CLIENT.replace(':clientID', clientID);

export const routeReviewByID = (id: string) => `${ROUTES.REVIEWS}/${id}`;
