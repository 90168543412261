import jwtDecode from 'jwt-decode';
import {
  AuthState,
  SIGNIN_SUCCESS,
  SIGNOUT,
  AuthActionTypes,
  LOG_IN_AS_CLIENT,
} from './types';

export const initialState: AuthState = {
  userID: null,
  jwt: null,
  prevJWT: null,
};

export function auth(state: AuthState = initialState, action: AuthActionTypes) {
  switch (action.type) {
    case SIGNIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SIGNOUT: {
      const { prevJWT } = state;

      if (!prevJWT) {
        return initialState;
      }

      const payload = jwtDecode<any>(prevJWT);

      if (payload && typeof payload === 'object' && payload.u) {
        // restore old jwt
        return {
          ...initialState,
          userID: payload.u,
          jwt: prevJWT,
          prevJWT: null,
        };
      }

      return initialState;
    }

    case LOG_IN_AS_CLIENT: {
      const jwt = action.payload;
      const payload = jwtDecode<any>(jwt);

      if (payload && typeof payload === 'object') {
        return {
          ...initialState,
          userID: payload.u,
          jwt,
          // backup old jwt
          prevJWT: state.jwt,
        };
      }

      return state;
    }
    default:
      return state;
  }
}
