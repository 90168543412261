import { path } from 'ramda';
import * as Sentry from '@sentry/react';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { HTTP_STATUS } from '../../httpStatus';
import { getStore } from '../../../store';
import { signOut } from '../../../store/auth/actions';

const graphqlExceptionCode = path<number>(['extensions', 'exception', 'code']);

export function handleApolloError({
  graphQLErrors,
  networkError,
}: ErrorResponse) {
  let errorRequiringReAuth;

  if (Array.isArray(graphQLErrors)) {
    errorRequiringReAuth = graphQLErrors.find((err) => {
      const code = graphqlExceptionCode(err);
      return (
        code === HTTP_STATUS.UPGRADE_REQUIRED ||
        code === HTTP_STATUS.UNAUTHORIZED
      );
    });

    // Capture all GraphQL errors with Sentry
    graphQLErrors.forEach((err) => {
      Sentry.withScope((scope) => {
        // Add additional context if needed
        if (err.path) {
          scope.setTag('GraphQL Path', err.path.join(' > '));
        }
        if (err.extensions) {
          scope.setExtras(err.extensions);
        }
        Sentry.captureException(new Error(err.message));
      });
    });
  }

  if (networkError) {
    Sentry.captureException(networkError);
  }

  if (
    errorRequiringReAuth ||
    (networkError as any)?.statusCode === HTTP_STATUS.UNAUTHORIZED
  ) {
    window.alert('Your session has expired.\nPlease log back in to continue');

    const store = getStore();
    store.dispatch(signOut());
  }
}

export const handleApolloErrorLink = onError(handleApolloError);
