import { AppState, SET_GRAPHQL_API_ENDPOINT, AppActionTypes } from './types';
import { env } from '../../config/env';

export const initialState: AppState = {
  graphQLAPIEndpoint: env.API_URI,
};

export function app(state: AppState = initialState, action: AppActionTypes) {
  switch (action.type) {
    case SET_GRAPHQL_API_ENDPOINT:
      return {
        ...state,
        graphQLAPIEndpoint: action.payload.endpoint,
      };

    default:
      return state;
  }
}
