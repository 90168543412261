import packageJson from '../../package.json';
import { createDeviceID } from '../app/createDeviceID';

type Env = {
  API_URI: string;
  APP_ID: string;
  APP_VERSION: string;
  DEBUG: 'console:*' | '-';
  DEVICE_ID: string;
  isDevelopment: boolean;
  isProduction: boolean;
  MAPBOX_API_KEY: string;
  MAPBOX_STYLE_URL: string;
  SENTRY_DSN: string;
  ENVIRONMENT: string;
  REDUX_PERSIST_KEY: string;
  REDUX_PERSIST_VERSION: number;
  LOG_ROCKET_ID: string;
  IS_RTM: boolean;
  IS_BETA: boolean;
  GIT_HASH: string;
  IS_LOCAL_HOST: boolean;
  ENABLE_MOBILE_DEBUG: boolean;
  APOLLO_PERSIST_ENABLE_DEBUGGING: boolean;
  APOLLO_PERSIST_KEY: string;
  APOLLO_PERSIST_VERSION: string;
  APOLLO_PERSIST_VERSION_LOCAL_STORAGE_KEY: string;
  CAPTURE_EVENTS_IN_LVR_API: boolean;
  PAYPAL_CLIENT_ID: string;
};

// these two are coming from the build scripts set in the package.json
const environment = process.env.REACT_APP_ENVIRONMENT;
const githash = process.env.REACT_APP_SENTRY_RELEASE;

const isRTM = environment === 'rtm';
const isBeta = environment === 'beta';
const isDevelopment = process.env.NODE_ENV === 'development';
const isProduction = !isDevelopment;

let appID = 'console-lithodomos-com-testing';

export const PROD_API_URI = 'https://janus.lithodomos.com/graphql';
export const TEST_API_URI = 'https://test.lithodomos.com/graphql';
export const LOCAL_API_URI = 'http://localhost:3000/graphql';

let apiURI = PROD_API_URI;

if (isBeta) {
  apiURI = TEST_API_URI;
}

if (isDevelopment) {
  apiURI = TEST_API_URI;
  // apiURI = LOCAL_API_URI;
}

if (isRTM) {
  appID = 'console.lithodomos.com';
}

export const env: Env = {
  isDevelopment,
  isProduction,
  DEBUG: isDevelopment ? 'console:*' : '-',
  API_URI: apiURI,
  APP_ID: appID,
  APP_VERSION: packageJson.version,
  DEVICE_ID: createDeviceID(),
  GIT_HASH: githash,
  // GOOGLE_MAPS_API_KEY: 'AIzaSyBvTcb4EzjXgyLUNc27mUYNR3WCaByajl4',
  MAPBOX_API_KEY:
    'pk.eyJ1IjoibGl0aG9kb21vc2FkbWluIiwiYSI6ImNqcWhrZ3g2ZzVyZ280M3IxeHNrZnVxamIifQ.APDnK4GZ_TBeYU417pgPpw',
  // Lithodomos custom style
  // MAPBOX_STYLE_URL: "mapbox://styles/lithodomosadmin/cjzm5me6a0hvf1cperzm79rl5",
  MAPBOX_STYLE_URL: 'mapbox://styles/mapbox/light-v10',
  SENTRY_DSN: 'https://c68cd87406114a2d9a73bf65c5f26e5b@sentry.io/1771611',
  LOG_ROCKET_ID: 'ax2r62/lithodomos-console',
  ENVIRONMENT: environment,
  REDUX_PERSIST_KEY: 'lvr-console-redux',
  REDUX_PERSIST_VERSION: 1,
  IS_RTM: isRTM,
  IS_BETA: isBeta,
  ENABLE_MOBILE_DEBUG: !isRTM,
  APOLLO_PERSIST_ENABLE_DEBUGGING: !isRTM,
  APOLLO_PERSIST_KEY: 'lvr-console-apollo',
  APOLLO_PERSIST_VERSION: '1',
  APOLLO_PERSIST_VERSION_LOCAL_STORAGE_KEY: 'lvr-console-persist-version',
  IS_LOCAL_HOST: Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  ),
  CAPTURE_EVENTS_IN_LVR_API: !isDevelopment,
  PAYPAL_CLIENT_ID: !isRTM
    ? // sandbox
      'AcJjtf357Xyw5qY1cf0p9IqiYvBNr6eZ-Bg3qJC4QSr9EgdtLBYD64XybhkJVyzbDgDg7JYYy0vzEhT3'
    : // live
      'AbhfpY7XefYH2Qq_by4yQmu6xk5Kv0jrB8JZtlZybTSarrrPuwRXVX2nvS4ToJnaZZUCFUYxJ3QqDLmG',
};

// @ts-ignore
window.APP_ENV = env;
