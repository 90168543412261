import { createContext, useState, useCallback, ReactNode } from 'react';
import { Layout } from 'react-grid-layout';

function getInitialLayout() {
  return [
    { i: 'a', x: 0, y: 0, w: 8, h: 3, minW: 3, minH: 3 },
    { i: 'b', x: 9, y: 0, w: 4, h: 3, minW: 3, minH: 3 },
    { i: 'c', x: 0, y: 3, w: 4, h: 3, minW: 2, minH: 3 },
    { i: 'd', x: 4, y: 3, w: 4, h: 3, minW: 2, minH: 3 },
    { i: 'e', x: 8, y: 3, w: 4, h: 3, minW: 2, minH: 3 },
  ];
}

export const DashboardLayoutContext = createContext({
  resetDashboardLayout: () => {},
  setDashboardLayout: (layout: Array<Layout>) => {},
  layout: getInitialLayout(),
});

interface Props {
  children: ReactNode;
}

export const DashboardLayoutProvider = ({ children }: Props) => {
  const [layout, setLayout] = useState<Array<Layout>>(getInitialLayout());

  const setDashboardLayout = useCallback((newLayout: Array<Layout>) => {
    setLayout(newLayout);
  }, []);

  const resetDashboardLayout = useCallback(() => {
    setLayout(getInitialLayout());
  }, []);

  return (
    <DashboardLayoutContext.Provider
      value={{
        setDashboardLayout,
        resetDashboardLayout,
        layout,
      }}
    >
      {children}
    </DashboardLayoutContext.Provider>
  );
};
