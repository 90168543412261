import './config/reporting/setupReporting';
import ReactDOM from 'react-dom';
import { App } from './app/App';
import { AppProviders } from './app/AppProviders';
import * as serviceWorker from './serviceWorker';
import { ErrorBoundary } from './components/ErrorBoundary';

ReactDOM.render(
  <ErrorBoundary>
    <AppProviders>
      <App />
    </AppProviders>
  </ErrorBoundary>,

  document.getElementById('root')
);

// We do not use a service worker
serviceWorker.unregister();
