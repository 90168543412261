import React from 'react';
import { LoadingScreen } from '../../components/loading/LoadingScreen';

const UnAuthedRoutesView = React.lazy(() => import('./UnAuthedRoutesView'));

export default function UnAuthedRoutes() {
  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <UnAuthedRoutesView />
    </React.Suspense>
  );
}
