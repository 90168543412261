import React from 'react';
import { LoadingScreen } from '../../components/loading/LoadingScreen';

const BusinessRoutesView = React.lazy(() => import('./BusinessRoutesView'));

export default function BusinessRoutes() {
  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <BusinessRoutesView />
    </React.Suspense>
  );
}
