import { ReactChild } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloCacheProvider } from './context/ApolloCacheProvider';
import { DashboardLayoutProvider } from './context/DashboardLayoutProvider';
import { createReduxStore } from '../store';
import { SagasContext } from '../store/types';

const sagaContext: SagasContext = {};

const { store, persistor } = createReduxStore(sagaContext);

interface Props {
  children: ReactChild;
}

export function AppProviders({ children }: Props) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ApolloCacheProvider>
            <DashboardLayoutProvider>{children}</DashboardLayoutProvider>
          </ApolloCacheProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
