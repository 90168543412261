import {
  ModalState,
  SET_MODAL_TYPE,
  ModalActionTypes,
  ModalType,
} from './types';

export const initialState: ModalState = {
  modalType: ModalType.NONE,
  modalData: null,
};

export function modal(
  state: ModalState = initialState,
  action: ModalActionTypes
) {
  switch (action.type) {
    case SET_MODAL_TYPE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
