import { useMutation, gql } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import jsonexport from 'jsonexport';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ProductSKU } from '../../graphql/globalTypes';
import {
  CONSOLE_DistributeAllCodesInDownloadClientRedemptionCodesDialog as Data,
  CONSOLE_DistributeAllCodesInDownloadClientRedemptionCodesDialogVariables as Variables,
} from './__generated__/CONSOLE_DistributeAllCodesInDownloadClientRedemptionCodesDialog';
import { formatDateStandard } from '../../utils/formatDateStandard';

export const DISTRIBUTE_ALL_CODES_MUTATION = gql`
  mutation CONSOLE_DistributeAllCodesInDownloadClientRedemptionCodesDialog(
    $input: SetUndistributedGiftPurchasesAsDistributedInput!
  ) {
    result: setUndistributedGiftPurchasesAsDistributed(input: $input) {
      count
      error {
        message
      }
    }
  }
`;

enum DistributedStatus {
  UNDISTRIBUTED = 'UNDISTRIBUTED',
  DISTRIBUTED = 'DISTRIBUTED',
  BOTH = 'BOTH',
}

type Purchase = {
  productSKU: ProductSKU;
  productID: string;
  redeemCode: string;
  redeemedAt: Date;
  distributedAt: Date | null;
};

interface Props {
  close: () => void;
  data: {
    purchases: Array<Purchase>;
    allTours: Array<{
      id: string;
      nameI18n: { en_US: string | null };
      internalReference: string;
    }>;
    appURL?: string;
  };
}

export const DownloadClientRedemptionCodesCSV = ({
  close,
  data: { purchases, allTours, appURL },
}: Props) => {
  const [distributedStatus, setDistributedStatus] = useState<DistributedStatus>(
    DistributedStatus.UNDISTRIBUTED
  );
  const [distribute, setDistribute] = useState<boolean>(
    distributedStatus !== DistributedStatus.DISTRIBUTED
  );
  const [distributeCodes, { loading }] = useMutation<Data, Variables>(
    DISTRIBUTE_ALL_CODES_MUTATION
  );

  const downloadCSV = async () => {
    try {
      const relevantPurchases = getRelevantPurchases(
        purchases,
        distributedStatus
      );
      const csvData = await exportToCSV({
        purchases: relevantPurchases,
        allTours,
        appURL,
      });

      const hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
      hiddenElement.target = '_blank';

      //provide the name for the CSV file to be downloaded
      hiddenElement.download = `AncientWorld_RedeemCodes_${format(
        new Date(),
        'yyyyMMdd'
      )}.csv`;

      if (distributedStatus !== DistributedStatus.DISTRIBUTED && distribute) {
        const products = getProducts(purchases);
        await distributeCodes({
          variables: {
            input: {
              notes: `Distributed when downloading the CSV file on ${formatDateStandard(
                new Date()
              )}`,
              products,
            },
          },
        });
      }

      hiddenElement.click();

      close();
    } catch (error) {
      toast.error('An error occurred while generating CSV file', {
        toastId: 'redeem_codes_csv_error',
        hideProgressBar: true,
        pauseOnHover: false,
      });
    }
  };

  const handleDistributedStatusChange = (event) => {
    setDistributedStatus(event.target.value);
  };

  const handleDistributeUndistributedCodesChange = (event) => {
    setDistribute(event.target.checked);
  };

  return (
    <Dialog
      open
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Download Redeem Codes as a CSV File
      </DialogTitle>

      <DialogContent>
        <Box>
          <Box>
            <Typography gutterBottom>
              If you want to feed the redeem codes into a separate CMS or simply
              to backup your codes, this feature will be helpful.
            </Typography>

            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Distributed status of the redeem codes to download"
                value={distributedStatus}
                onChange={handleDistributedStatusChange}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={DistributedStatus.UNDISTRIBUTED}
                  control={<Radio />}
                  label="Download only the undistributed redeem codes"
                />
                <FormControlLabel
                  value={DistributedStatus.DISTRIBUTED}
                  control={<Radio />}
                  label="Download only the distributed redeem codes"
                />
                <FormControlLabel
                  value={DistributedStatus.BOTH}
                  control={<Radio />}
                  label="Download all redeem codes"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Divider />

          <Box>
            <FormControlLabel
              disabled={distributedStatus === DistributedStatus.DISTRIBUTED}
              control={<Checkbox defaultChecked={distribute} />}
              label="Mark all undistributed codes as distributed"
              onChange={handleDistributeUndistributedCodesChange}
            />

            <Typography>
              Recommended to check this checkbox if you are uploading the codes
              to a CMS to prevent duplicates
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            await downloadCSV();
          }}
          color="primary"
          variant="outlined"
          autoFocus
          disabled={loading}
        >
          {loading ? 'Processing...' : 'Download CSV File'}
        </Button>
        <Button onClick={close} color="secondary" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function getRelevantPurchases(
  purchases: Array<Purchase>,
  distributedStatus: DistributedStatus
) {
  if (distributedStatus === DistributedStatus.DISTRIBUTED) {
    return purchases.filter((p) => p.distributedAt);
  }

  if (distributedStatus === DistributedStatus.UNDISTRIBUTED) {
    return purchases.filter((p) => !p.distributedAt);
  }

  return purchases;
}

export function getProducts(purchases: Array<Purchase>) {
  let productsMap: { [key: string]: string[] } = {};

  productsMap = purchases.reduce((accum, p) => {
    const currentProductIDs = accum[p.productSKU] || [];

    accum[p.productSKU] = [...currentProductIDs, p.productID];

    return accum;
  }, {});

  // The above reducer gives this
  // {
  //   productSKU1: [productID1, productID2],
  //   productSKU2: [productIDA, productIDB]
  // }

  const products = Object.keys(productsMap).reduce(
    (accum, productSKU) => [
      ...accum,
      ...productsMap[productSKU].map((productID) => ({
        productSKU,
        productID,
      })),
    ],
    []
  );

  // The above gives
  // [
  //   { productSKU: 'productSKU1', productID: 'productID1' },
  //   { productSKU: 'productSKU1', productID: 'productID2' },
  //   { productSKU: 'productSKU2', productID: 'productIDA' },
  //   { productSKU: 'productSKU2', productID: 'productIDB' },
  // ];

  return products;
}

type ExportToCSVArgs = {
  purchases: Array<Purchase>;
  allTours: Array<{
    id: string;
    nameI18n: { en_US: string | null };
    internalReference: string;
  }>;
  appURL?: string;
};

async function exportToCSV({
  purchases,
  allTours,
  appURL,
}: ExportToCSVArgs): Promise<string> {
  const toursMap = allTours.reduce((accum, tour) => {
    const tourName = tour.nameI18n?.en_US;
    const internalReference = tour?.internalReference;
    if (tourName) {
      accum[tour.id] = { tourName, internalReference };
    }

    return accum;
  }, {});
  const rawData = purchases
    .map((p) => ({
      link: getProductLinkWithRCodeByInternalReference(
        toursMap[p.productID]?.internalReference,
        p.redeemCode,
        appURL
      ),
      tourname: toursMap[p.productID].tourName || p.productID,
      code: p.redeemCode,
      redeemedAt: p.redeemedAt,
    }))
    .sort(sortByTourname);

  return new Promise((resolve, reject) => {
    jsonexport(rawData, function (err: Error, csv: string) {
      if (err) {
        reject(err);
      }

      resolve(csv);
    });
  });
}

type CSVRow = {
  tourname: string;
  code: string;
  redeemedAt: any;
};

function sortByTourname(a: CSVRow, b: CSVRow) {
  var nameA = a.tourname.toUpperCase(); // ignore upper and lowercase
  var nameB = b.tourname.toUpperCase(); // ignore upper and lowercase

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

export function getProductLinkWithRCodeByInternalReference(
  internalReference: string,
  rcode: string,
  appURL?: string
) {
  if (appURL) {
    return `${appURL}?rcode=${rcode}`;
  }

  switch (true) {
    //discover
    case internalReference === 'ancient_acropolis':
      return `https://discover.lithodomos.com/athenian-acropolis?rcode=${rcode}`;
    case internalReference === 'ancient_paestum':
      return `https://discover.lithodomos.com/paestum?rcode=${rcode}`;
    case internalReference === 'ancient_london':
      return `https://discover.lithodomos.com/london?rcode=${rcode}`;
    case internalReference === 'expedition_malaga':
      return `https://discover.lithodomos.com/malaga?rcode=${rcode}`;
    case internalReference === 'expedition_barcelona':
      return `https://discover.lithodomos.com/barcelona?rcode=${rcode}`;
    case internalReference === 'discover_medina':
      return `https://discover.lithodomos.com/medina-azahara?rcode=${rcode}`;
    //guide
    case internalReference?.includes('cambridge'):
      return `https://guide.lithodomos.com/cambridge?rcode=${rcode}`;
    case internalReference?.includes('fatima'):
      return `https://guide.lithodomos.com/cityrama_fatima?rcode=${rcode}`;
    case internalReference?.includes('hellenic'):
      return `https://guide.lithodomos.com/hellenicmuseum?rcode=${rcode}`;
    case internalReference?.includes('notfair'):
      return `https://guide.lithodomos.com/notfair?rcode=${rcode}`;
    case internalReference === 'ancient_ostia':
      return `https://guide.lithodomos.com/notfair?rcode=${rcode}`;
    //default
    default:
      return `https://ancient-world.co?rcode=${rcode}`;
  }
}
