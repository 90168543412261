import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';
import { ErrorScreen } from './error/ErrorScreen';

type Props = {
  children: ReactNode;
};

export const ErrorBoundary = ({ children }: Props) => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorScreen} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  );
};
