import * as Sentry from '@sentry/react';
import { from, ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import Cookies from 'js-cookie';
import { CachePersistor } from 'apollo3-cache-persist';
import { RetryLink } from '@apollo/client/link/retry';
import indexedDBStorage from '../../utils/indexedDBStorage';
import { env } from '../env';
import { createAuthHeadersLink } from './links/createAuthHeadersLink';
import { createLithodomosHeadersLink } from './links/createLithodomosHeadersLink';
import { handleApolloErrorLink } from './links/handleApolloErrorLink';
import { createEndpointLink } from './links/createEndpointLink';

// been getting an ncaught Error: The error you provided does not contain a stack trace.
// https://github.com/apollographql/apollo-client/issues/6769
const fetchOptions: any = {};

if (window.AbortController) {
  const controller = new AbortController();
  const signal = controller.signal;

  fetchOptions.signal = signal;
}

export let client: ApolloClient<any>;
export let _apolloCachePersitor: CachePersistor<any>;

export const configureApollo = async (): Promise<ApolloClient<any>> => {
  const cache = new InMemoryCache();

  // persist in-memory cache to disk.
  _apolloCachePersitor = new CachePersistor({
    cache,
    // indexedDBStorage is used because it's async and can hold a large amount of data compared to localstorage
    // @ts-ignore
    storage: indexedDBStorage,
    key: env.APOLLO_PERSIST_KEY,
    maxSize: false,
    debug: env.APOLLO_PERSIST_ENABLE_DEBUGGING,
  });

  // note: increment the APOLLO_PERSIST_VERSION_LOCAL_STORAGE_KEY value in env to delete old cache for everyone.
  const currentVersion = window.localStorage
    ? window.localStorage.getItem(env.APOLLO_PERSIST_VERSION_LOCAL_STORAGE_KEY)
    : Cookies.get(env.APOLLO_PERSIST_VERSION_LOCAL_STORAGE_KEY);

  try {
    if (currentVersion === env.APOLLO_PERSIST_VERSION) {
      // load the persisted cache into memory if the versions match.
      await _apolloCachePersitor.restore();
    } else {
      // clear the persisted cache if versions mismatch.
      await _apolloCachePersitor.purge();

      if (window.localStorage) {
        window.localStorage.setItem(
          env.APOLLO_PERSIST_VERSION_LOCAL_STORAGE_KEY,
          env.APOLLO_PERSIST_VERSION
        );
      } else {
        Cookies.set(
          env.APOLLO_PERSIST_VERSION_LOCAL_STORAGE_KEY,
          env.APOLLO_PERSIST_VERSION
        );
      }
    }
  } catch (error) {
    Sentry.captureMessage(
      `configureApollo.ts persist restore or purge error: ${
        error?.message || 'Unknown'
      }`
    );
  }

  // set the headers for the request. this is where we would set the
  // authentication token if one exists, for example.
  const authLink = createAuthHeadersLink();

  // retry few times if the request fails for whatever reason
  const retryLink = new RetryLink();

  // some of the required header for the API to identify the app for example.
  const lithodomosHeadersLink = createLithodomosHeadersLink();

  const httpLink = new HttpLink({
    // uri: env.API_URI,
    fetchOptions,
  });

  client = new ApolloClient<any>({
    link: from([
      createEndpointLink(),
      handleApolloErrorLink,
      authLink,
      lithodomosHeadersLink,
      retryLink,
      httpLink,
    ]),
    cache,
  });

  return client;
};

export const getApolloClient = () => {
  if (!client) {
    throw new Error('client should have been initialsed first');
  }

  return client;
};
