export type ScriptsState = {
  paypal: ScriptLoadingStatus;
  googleMaps: ScriptLoadingStatus;
};

export enum ScriptNames {
  paypal = 'paypal',
  googleMaps = 'googleMaps',
}

export const SET_SCRIPT_LOADING_STATUS = 'SET_SCRIPT_LOADING_STATUS';

export enum ScriptLoadingStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  IDLE = 'IDLE',
  ERROR = 'ERROR',
}

export type SetScriptLoadingStatusPayload = {
  name: ScriptNames;
  status: ScriptLoadingStatus;
};

interface SetScriptLoadingStatusAction {
  type: typeof SET_SCRIPT_LOADING_STATUS;
  payload: SetScriptLoadingStatusPayload;
}

export type ScriptsActionTypes = SetScriptLoadingStatusAction;
