import { useState, useEffect } from 'react';

// hook to call a function before a render
export const useBeforeFirstRender = (f: Function) => {
  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => setHasRendered(true), [hasRendered]);

  if (!hasRendered) {
    f();
  }
};
