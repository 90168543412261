import { gql } from '@apollo/client';

export const DISTRIBUTE_REDEEM_CODE = gql`
  mutation CONSOLE_DistributeRedeemCode(
    $input: SetGiftPurchaseDistributedInput!
  ) {
    result: setGiftPurchaseDistributed(input: $input) {
      distributed
      error {
        message
      }
    }
  }
`;
