import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { currentUserID } from '../../store/auth/selectors';

// better to do this in a saga
export const StateListener = () => {
  const userID = useSelector(currentUserID);

  useEffect(() => {
    if (userID) {
      Sentry.setUser({ id: userID });
    } else {
      Sentry.setUser(null);
    }
  }, [userID]);

  return null;
};
