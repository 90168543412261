import {
  AuthActionTypes,
  SIGNIN_SUCCESS,
  SIGNOUT,
  LOG_IN_AS_CLIENT,
  SignInSuccessPayload,
  LogInAsClientPayload,
} from './types';

export function signInSuccess(payload: SignInSuccessPayload): AuthActionTypes {
  return {
    type: SIGNIN_SUCCESS,
    payload,
  };
}

export function signOut(): AuthActionTypes {
  return {
    type: SIGNOUT,
  };
}

export function logInAsClient(payload: LogInAsClientPayload): AuthActionTypes {
  return {
    type: LOG_IN_AS_CLIENT,
    payload,
  };
}
