import { setContext } from '@apollo/client/link/context';
import { getStore } from '../../../store';

export const createAuthHeadersLink = () =>
  setContext((_, previousContext) => {
    // extract the auth token from redux store and set on the headers.
    const store = getStore();
    const state = store.getState();
    const jwt = state.auth.jwt;

    if (typeof jwt === 'string') {
      return {
        ...previousContext,
        headers: {
          ...previousContext.headers,
          authorization: `Bearer ${jwt}`,
        },
      };
    }

    const headers = previousContext.headers || {};

    // Cleanup any authorization headers if none are stored via
    delete headers.authorization;

    previousContext.headers = headers;

    return previousContext;
  });
