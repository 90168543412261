export const AUTH_TOKEN_KEY = 'token';

export const ANALYTICS_OPTIONS_KEY = 'analytics_options';
export const VIEWPOINTS_USAGE_ANALYTICS_OPTIONS_KEY =
  'viewpoints_usage_analytics_options';
export const LANGUAGE_USAGE_ANALYTICS_OPTIONS_KEY =
  'language_usage_analytics_options';
export const APP_USAGE_ANALYTICS_OPTIONS_KEY = 'app_usage_analytics_options';
export const USER_METRICS_OPTIONS_KEY = 'user_metrics_options';
export const ACTIVATIONS_OPTIONS_KEY = 'activations_options';

// Taken from @lvr/http-core
export enum LITHODOMOS_HEADERS {
  APP_ID = 'x-lvr-app-id',
  APP_VERSION = 'x-lvr-app-version',
  CORRELATION_ID = 'x-lvr-correlation-id',
  DEVICE_ID = 'x-lvr-device-id',
  TIMEZONE = 'x-lvr-timezone',
}

export enum INTERNAL_ROLES {
  ADMIN = 0,
  // USER = 1,

  PARTNER = 11,

  DEVELOPER = 20,
  TOUR_OPERATOR = 22,
  TESTING = 23,
  STAFF = 24,
  DEMO = 25,
  SALES = 27,
}

export enum CLIENT_ROLES {
  CLIENT_ADMIN = 100,
  CLIENT_USER = 101,
}

export enum ROLES {
  ADMIN = 0,
  // USER = 1,

  PARTNER = 11,

  DEVELOPER = 20,
  TOUR_OPERATOR = 22,
  TESTING = 23,
  STAFF = 24,
  DEMO = 25,
  SALES = 27,

  CLIENT_ADMIN = 100,
  CLIENT_USER = 101,
}

export enum PublishedState {
  BOTH_PUBLISHED_AND_UNPUBLISHED = 'BOTH_PUBLISHED_AND_UNPUBLISHED',
  PUBLISHED_ONLY = 'PUBLISHED_ONLY',
  UNPUBLISHED_ONLY = 'UNPUBLISHED_ONLY',
}
