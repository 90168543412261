import { ROLES } from '../../config/enums';

export const CLIENT_ADMIN_ONLY = [ROLES.CLIENT_ADMIN];
export const ALL_CLIENT_USERS = [ROLES.CLIENT_ADMIN, ROLES.CLIENT_USER];

export enum ROUTES {
  DASHBOARD = '/dashboard',

  ACCOUNT = '/account',
  ACCOUNT_DETAILS = '/account/details',
  ACCOUNT_SECURITY = '/account/security',
  ACCOUNT_USERS = '/account/users',

  ANALYTICS = '/analytics',
  ANALYTICS_TOURS = '/analytics/tours',
  ANALYTICS_TRAFFIC = '/analytics/traffic',
  ANALYTICS_USERS = '/analytics/users',

  MARKETING = '/marketing',
  MARKETING_TOUR = '/marketing/:id',

  REPORTING = '/reports',
  REPORTING_REDEMPTION_CODE_PURCHASES = '/reports/redemption_code_purchases',
  REPORTING_REDEMPTION_CODE_USAGE = '/reports/redemption_code_usage',
  REPORTING_PROMOTIONAL_CODE_USAGE = '/reports/promotional_code_usage',
  REPORTING_BUNDLE_REDEEM_CODE_USAGE = '/reports/bundle_redeem_code_usage',
  REPORTING_CLC_STAGE_4_PURCHASES = '/reports/clc_stage_4_purchases',
  REPORTING_AFFILIATE_CODE_PURCHASES = '/reports/affiliate_code_purchases',
  REPORTING_BULK_PURCHASES = '/reports/bulk_purchases',
  REPORTING_TRAFFIC = '/reports/traffic',

  AFFILIATECODE = '/affiliatecode',
  PRICING = '/pricing',

  PURCHASES = '/purchases',
  PURCHASES_CHECKOUT = '/purchases/checkout',
  PURCHASES_ORDER_CONFIRM = '/purchases/order/confirm',
  PURCHASES_SHOP = '/purchases/shop',
  PURCHASES_ORDER = '/purchases/order',
  PURCHASES_ITEM = '/purchases/:productID',
  PURCHASES_BUNDLE_ITEM = '/purchases/bundle/:bundleName',

  INVOICES = '/invoices',
  INVOICE = '/invoices/:invoiceID',
}

export const ROUTE_CONFIG = {
  DASHBOARD: {
    path: ROUTES.DASHBOARD,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  ACCOUNT: {
    path: ROUTES.ACCOUNT,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  ACCOUNT_DETAILS: {
    path: ROUTES.ACCOUNT_DETAILS,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  ACCOUNT_SECURITY: {
    path: ROUTES.ACCOUNT_SECURITY,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  ACCOUNT_USERS: {
    path: ROUTES.ACCOUNT_USERS,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  PURCHASES: {
    path: ROUTES.PURCHASES,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  PURCHASES_ITEM: {
    path: ROUTES.PURCHASES_ITEM,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  PURCHASES_BUNDLE_ITEM: {
    path: ROUTES.PURCHASES_BUNDLE_ITEM,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  PURCHASES_SHOP: {
    path: ROUTES.PURCHASES_SHOP,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  PURCHASES_ORDER: {
    path: ROUTES.PURCHASES_ORDER,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  PURCHASES_CHECKOUT: {
    path: ROUTES.PURCHASES_CHECKOUT,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  PURCHASES_ORDER_CONFIRM: {
    path: ROUTES.PURCHASES_ORDER_CONFIRM,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  ANALYTICS: {
    path: ROUTES.ANALYTICS,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  ANALYTICS_TOURS: {
    path: ROUTES.ANALYTICS_TOURS,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  ANALYTICS_TRAFFIC: {
    path: ROUTES.ANALYTICS_TRAFFIC,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  ANALYTICS_USERS: {
    path: ROUTES.ANALYTICS_USERS,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  MARKETING_TOURS: {
    path: ROUTES.MARKETING,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  MARKETING_TOUR: {
    path: ROUTES.MARKETING_TOUR,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  REPORTING: {
    path: ROUTES.REPORTING,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  REPORTING_REDEMPTION_CODE_PURCHASES: {
    path: ROUTES.REPORTING_REDEMPTION_CODE_PURCHASES,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  REPORTING_REDEMPTION_CODE_USAGE: {
    path: ROUTES.REPORTING_REDEMPTION_CODE_USAGE,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  REPORTING_PROMOTIONAL_CODE_USAGE: {
    path: ROUTES.REPORTING_PROMOTIONAL_CODE_USAGE,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  REPORTING_BUNDLE_REDEEM_CODE_USAGE: {
    path: ROUTES.REPORTING_BUNDLE_REDEEM_CODE_USAGE,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  REPORTING_CLC_STAGE_4_PURCHASES: {
    path: ROUTES.REPORTING_CLC_STAGE_4_PURCHASES,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  REPORTING_AFFILIATE_CODE_PURCHASES: {
    path: ROUTES.REPORTING_AFFILIATE_CODE_PURCHASES,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  REPORTING_TRAFFIC: {
    path: ROUTES.REPORTING_TRAFFIC,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  AFFILIATECODE: {
    path: ROUTES.AFFILIATECODE,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  PRICING: {
    path: ROUTES.PRICING,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  INVOICES: {
    path: ROUTES.INVOICES,
    authorisedRoles: ALL_CLIENT_USERS,
  },
  INVOICE: {
    path: ROUTES.INVOICE,
    authorisedRoles: ALL_CLIENT_USERS,
  },
};
