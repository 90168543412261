export enum ROUTES {
  INDEX = '/',
  SIGNIN = '/auth/signin',
  SIGNUP = '/auth/signup',
  FORGOT_PASSWORD = '/auth/forgot',
  RESET_PASSWORD = '/auth/reset',
  PARTNER_CONTACT = '/auth/contact',
}

export const ROUTE_CONFIG = {
  INDEX: {
    path: ROUTES.INDEX,
  },
  LOGIN: {
    path: ROUTES.SIGNIN,
  },
  SIGNUP: {
    path: ROUTES.SIGNUP,
  },
  FORGOT_PASSWORD: {
    path: ROUTES.FORGOT_PASSWORD,
  },
  RESET_PASSWORD: {
    path: ROUTES.RESET_PASSWORD,
  },
  PARTNER_CONTACT: {
    path: ROUTES.PARTNER_CONTACT,
  },
};
