import { AccessSubType, CurrencyCode } from '../../graphql/globalTypes';
import {
  CartActionTypes,
  CartState,
  CLEAR_CART,
  CurrencyCodeSetMode,
  SET_BUNDLE_ITEMS_IN_CART,
  SET_CURRENCY_CODE,
  SET_ITEMS_IN_CART_ALLOWED,
  SIGNOUT,
} from './types';

export const initialState: CartState = {
  items: [],
  currencyCode: CurrencyCode.EUR,
  currencyCodeSetMode: CurrencyCodeSetMode.DEFAULT,
};

export function cart(state: CartState = initialState, action: CartActionTypes) {
  switch (action.type) {
    case SET_CURRENCY_CODE:
      return { ...state, ...action.payload };

    case SET_ITEMS_IN_CART_ALLOWED:
      const { payload } = action;
      const { count, productSKU, productID, currencyCode } = payload;
      const nextCurrencyCode = currencyCode || state.currencyCode;

      let found = false;
      let nextCartItems = [];

      // 2. if the count is non-zero, set it in cart

      state.items.forEach((item) => {
        // if the item is not in the cart, and
        const isInCart =
          item.productSKU === productSKU && item.productID === productID;

        found = found || isInCart;

        if (!isInCart) {
          // if not in cart, push the cart item as is

          nextCartItems.push(item);
        } else if (count > 0) {
          // if in cart, and the count is more than 0, add the item with new count
          nextCartItems.push({
            productID: payload.productID,
            productSKU: payload.productSKU,
            count: payload.count,
            currencyCode: payload.currencyCode,
            accessSubType: payload.accessSubType,
            guideNumber: payload.guideNumber,
          });
        }
      });

      // if did not exist in cart and count is more than 0, append to end
      if (!found && count > 0) {
        nextCartItems.push(payload);
      }

      const nextState = {
        ...state,
        items: nextCartItems,
        currencyCode: nextCurrencyCode,
      };

      return nextState;

    case SET_BUNDLE_ITEMS_IN_CART:
      const {
        bundleName,
        productSKU: bundleSKU,
        count: bundleCount,
        currencyCode: bundleCurrencyCode,
        redeemableTourIDs,
        accessSubType,
        thumbnailURI,
        guideNumber,
        bundleID,
      } = action.payload;

      const nextBundleCurrencyCode = bundleCurrencyCode || state.currencyCode;

      let exist = false;
      let nextBundleCartItems = [];

      state.items.forEach((item) => {
        if (
          item.productID ||
          (item.bundleName && item.bundleName !== bundleName)
        ) {
          nextBundleCartItems.push(item);
        } else {
          if (
            bundleCount > 0 &&
            (accessSubType !== AccessSubType.GUIDE || guideNumber > 0)
          ) {
            const isInCart = item.bundleName === bundleName;

            exist = exist || isInCart;

            if (isInCart) {
              nextBundleCartItems.push({
                bundleName: bundleName,
                productSKU: bundleSKU,
                count: bundleCount,
                redeemableTourIDs,
                accessSubType,
                thumbnailURI,
                guideNumber:
                  accessSubType === AccessSubType.GUIDE ? guideNumber : null,
                bundleID,
              });
            }
          }
        }
      });

      // if did not exist in cart and count is more than 0, append to end
      if (
        !exist &&
        bundleCount > 0 &&
        (accessSubType !== AccessSubType.GUIDE || guideNumber > 0)
      ) {
        nextBundleCartItems.push({
          bundleName: bundleName,
          productSKU: bundleSKU,
          count: bundleCount,
          redeemableTourIDs,
          accessSubType,
          thumbnailURI,
          guideNumber:
            accessSubType === AccessSubType.GUIDE ? guideNumber : null,
          bundleID,
        });
      }

      const nextBundleState = {
        ...state,
        items: nextBundleCartItems,
        currencyCode: nextBundleCurrencyCode,
      };

      return nextBundleState;
    case CLEAR_CART:
      return { ...state, items: [] };

    case SIGNOUT:
      return initialState;

    default:
      return state;
  }
}
