import { RootState } from '../types';
import { CartState, CartItem, CurrencyCodeSetMode } from './types';
import { CurrencyCode, ProductSKU } from '../../graphql/globalTypes';

function selectCart(state: RootState): CartState {
  return state.cart;
}

export function selectCartItems(state: RootState): Array<CartItem> {
  return selectCart(state).items;
}

export function selectTourIDsInCart(state: RootState): Array<string> {
  return selectCartItems(state)
    .filter((item) => item.productSKU === ProductSKU.PLAYLIST)
    .map((item) => item.productID);
}

export function selectCurrencyCode(state: RootState): CurrencyCode {
  return selectCart(state).currencyCode;
}

export function selectCurrencyCodeSetMode(
  state: RootState
): CurrencyCodeSetMode {
  return selectCart(state).currencyCodeSetMode;
}
