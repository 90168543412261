import PQueue from 'p-queue';
import { v4 as uuidv4 } from 'uuid';
import { getTimezone } from '../../utils/getTimezone';
import { AnalyticEventInput } from '../../graphql/globalTypes';
import { DataKeys, EventNames } from './types';
import { createOrGetSession } from './createOrGetSession';
import { submitToBackend } from './submitToBackend';
import { getStore } from '../../store';
import { env } from '../../config/env';

const queue = new PQueue({ concurrency: 1 });

type EventArg = {
  name: EventNames;
  data?: Array<{ key: DataKeys; value: string }>;
};

export async function captureEvent({
  name,
  data = [],
}: EventArg): Promise<AnalyticEventInput> {
  const sessionID = createOrGetSession();
  const timezone = getTimezone();

  const event: AnalyticEventInput = {
    name,
    data,
    appID: env.APP_ID,
    appVersion: env.APP_VERSION,
    deviceID: env.DEVICE_ID,
    timestamp: new Date().toISOString(),
    eventID: uuidv4(),
    sessionID,
  };

  if (timezone) {
    event.timezone = timezone;
  }

  // get userID
  const { userID } = getStore().getState().auth;
  if (userID) {
    event.userID = userID;
  }

  if (env.CAPTURE_EVENTS_IN_LVR_API) {
    queue.add(() => submitToBackend(event));
  }

  if (env.isDevelopment) {
    console.log(event);
  }

  return event;
}
