import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { env } from '../config/env';
import { Button, FormControl, InputLabel, Input } from '@material-ui/core';

const PASSWORD = 'lithodomos';
const KEY = 'allowaccess';

export const withTestSiteProtection = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: P) => {
    const inputEl = useRef<HTMLInputElement>(null);
    const [allowAccess, setAllowAccess] = useState<boolean>(
      localStorage.getItem(KEY) === 'true'
    );
    const isRTM = env.IS_RTM;
    const isLocalHost = env.IS_LOCAL_HOST;
    const isDevelopment = env.isDevelopment;

    const [password, setPassword] = useState<string>('');

    if (isRTM || isDevelopment || isLocalHost || allowAccess) {
      return <WrappedComponent {...props} />;
    }

    const handleInputChange = (e: any) => {
      const value = e.target.value;
      setPassword(value);

      if (value.toLowerCase() === PASSWORD) {
        localStorage.setItem(KEY, 'true');
        setAllowAccess(true);
      }
    };

    const handleSubmit = () => {
      if (password.toLowerCase() === PASSWORD) {
        localStorage.setItem(KEY, 'true');
        setAllowAccess(true);
      } else {
        window.alert('Wrong password. Try again.');
        setPassword('');

        if (inputEl.current) {
          inputEl.current.focus();
        }
      }
    };

    return (
      <FullscreenPageContainer>
        <Container>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleInputChange}
              value={password}
            />
          </FormControl>

          <Button
            onClick={handleSubmit}
            disabled={password.trim().length === 0}
            variant="outlined"
          >
            Enter Test Site
          </Button>
        </Container>
      </FullscreenPageContainer>
    );
  };
};

const FullscreenPageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  height: 200px;
  width: 400px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 20px;
`;
