import { RootState } from '../types';
import jwtDecode from 'jwt-decode';
import { AuthState } from './types';
import { ROLES } from '../../config/auth';

function getRoles(jwt: string) {
  const payload = jwtDecode<any>(jwt);

  let roles = [];

  if (
    payload &&
    typeof payload === 'object' &&
    Array.isArray(payload.r) &&
    payload.r.every((r) => typeof r === 'number')
  ) {
    roles = payload.r;
  }

  return roles;
}

function getAuthState(state: RootState): AuthState {
  return state.auth;
}

export function isAuthenticated(state: RootState): boolean {
  return Boolean(getAuthState(state).userID);
}

export function isInternal(state: RootState): boolean {
  const { jwt } = getAuthState(state);

  if (!jwt) {
    return false;
  }

  const roles = getRoles(jwt);

  return [ROLES.SALES, ROLES.ADMIN, ROLES.STAFF].some((r) => roles.includes(r));
}

export function currentUserID(state: RootState): string | null {
  return getAuthState(state).userID;
}

export function isAdmin(state: RootState): boolean {
  const { jwt } = getAuthState(state);

  if (!jwt) {
    return false;
  }

  const roles = getRoles(jwt);

  return [ROLES.ADMIN].some((r) => roles.includes(r));
}

export function isSales(state: RootState): boolean {
  const { jwt } = getAuthState(state);

  if (!jwt) {
    return false;
  }

  const roles = getRoles(jwt);

  return [ROLES.SALES].some((r) => roles.includes(r));
}

export function isClientAdmin(state: RootState): boolean {
  const { jwt } = getAuthState(state);

  if (!jwt) {
    return false;
  }

  const roles = getRoles(jwt);

  return [ROLES.CLIENT_ADMIN].some((r) => roles.includes(r));
}

export function isClientUser(state: RootState): boolean {
  const { jwt } = getAuthState(state);

  if (!jwt) {
    return false;
  }

  const roles = getRoles(jwt);

  return [ROLES.CLIENT_USER].some((r) => roles.includes(r));
}

export function isClient(state: RootState): boolean {
  const { jwt } = getAuthState(state);

  if (!jwt) {
    return false;
  }

  const roles = getRoles(jwt);

  return [ROLES.CLIENT_ADMIN, ROLES.CLIENT_USER].some((r) => roles.includes(r));
}

export function userRoles(state: RootState): Array<number> {
  const { jwt } = getAuthState(state);

  if (!jwt) {
    return [];
  }

  return getRoles(jwt);
}
