import React, { useEffect } from 'react';
import QRCode from 'qrcode.react';
import { Page, Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
  },
  section: {
    margin: 10,
    padding: 10,
  },
  text: {
    margin: 10,
  },
  title: {
    fontWeight: 'bold',
    margin: 10,
  },
});

interface InstructionsProps {
  token: string;
  appURL: string | null;
  qrCodeBase64: string | null;
  index: number;
}

export const DownloadPDFInstructions = (props: InstructionsProps) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>Instructions:</Text>
        <Text style={styles.text}>Method 1:</Text>
        <Text style={styles.text}>1. Open the camera.</Text>
        <Text style={styles.text}>2. Aim at QR code.</Text>
        <Text style={styles.text}>3. Tap the link.</Text>
        {props.qrCodeBase64 && (
          <Image src={props.qrCodeBase64} style={{ width: 150, height: 150 }} />
        )}
        <Text style={styles.text}>Method 2:</Text>
        <Text style={styles.text}>1. Visit:</Text>
        <Link style={styles.title} src={props.appURL}>
          {props.appURL}
        </Link>
        <Text style={styles.text}>2. Click the Redeem icon.</Text>
        <Text style={styles.text}>3. Sign up.</Text>
        <Text style={styles.text}>4. Enter code:</Text>
        <Text style={styles.title}>{props.token}</Text>
        <Text style={styles.text}>5. Click REDEEM.</Text>
      </View>
      <View style={styles.section}>
        <Text>{`P${props.index + 1}`}</Text>
      </View>
    </Page>
  );
};

type Props = {
  setQrCodeBase64: Function;
  token: string;
  index: number;
  appURL: string;
};
export const QRCodeComponent = ({
  setQrCodeBase64,
  token,
  index,
  appURL,
}: Props) => {
  useEffect(
    () => {
      const qrCodeCanvas = document.getElementById(
        `qrCodeCanvas${index}`
      ) as HTMLCanvasElement;
      const qrCodeDataUri = qrCodeCanvas?.toDataURL();
      if (qrCodeDataUri) {
        setQrCodeBase64((base64URL) => [...base64URL, qrCodeDataUri]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <QRCode
        style={{ visibility: 'hidden' }}
        id={`qrCodeCanvas${index}`}
        level="M"
        size={110}
        renderAs="canvas"
        value={`${appURL}?rcode=${token}`}
      />
    </>
  );
};
