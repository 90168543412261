// @flow
import { setContext } from '@apollo/client/link/context';
import { LITHODOMOS_HEADERS } from '../../enums';
import { env } from '../../env';

export const createLithodomosHeadersLink = () =>
  setContext((_, previousContext) => {
    const lithodomosHeaders = {
      [LITHODOMOS_HEADERS.APP_ID]: env.APP_ID,
      [LITHODOMOS_HEADERS.APP_VERSION]: env.APP_VERSION,
      [LITHODOMOS_HEADERS.DEVICE_ID]: env.DEVICE_ID,
      [LITHODOMOS_HEADERS.TIMEZONE]: Intl.DateTimeFormat().resolvedOptions()
        .timeZone,
    };

    return {
      ...previousContext,
      headers: {
        ...previousContext.headers,
        ...lithodomosHeaders,
      },
    };
  });
