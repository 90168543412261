import { v4 as uuidv4 } from 'uuid';
import {
  browserName,
  browserVersion,
  deviceType,
  osName,
  osVersion,
  mobileModel,
  mobileVendor,
} from 'react-device-detect';
import { nonNullable } from '../../utils/nonNullable';
import { captureEvent } from './captureEvent';
import { DataKeys, EventNames } from './types';

let _sessionID: string | null = null;

export function createOrGetSession() {
  if (!_sessionID) {
    _sessionID = uuidv4();

    const data = [
      mobileVendor && {
        key: DataKeys.DEVICE_VENDOR,
        value: mobileVendor,
      },
      mobileModel && {
        key: DataKeys.DEVICE_MODEL,
        value: mobileModel,
      },
      deviceType && {
        key: DataKeys.DEVICE_TYPE,
        value: deviceType,
      },
      osName && {
        key: DataKeys.OS,
        value: osName,
      },
      osVersion && {
        key: DataKeys.OS_VERSION,
        value: osVersion,
      },
      browserName && {
        key: DataKeys.BROWSWE_NAME,
        value: browserName,
      },
      browserVersion && {
        key: DataKeys.BROWSWE_VERSION,
        value: browserVersion,
      },
    ].filter(nonNullable);

    // send it to the backend
    captureEvent({ name: EventNames.SESSION_START, data });
  }

  return _sessionID;
}
