import { RootState } from '../types';
import { ModalType } from './types';

export function selectModalType(state: RootState): ModalType {
  return state.modal.modalType;
}

export function selectModalData(state: RootState): any {
  return state.modal.modalData;
}
