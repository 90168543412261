import {
  CONSOLE_SubmitAnalyticEvents as Data,
  CONSOLE_SubmitAnalyticEventsVariables as Variables,
} from './__generated__/CONSOLE_SubmitAnalyticEvents';
import { AnalyticEventInput } from '../../graphql/globalTypes';
import { gql } from '@apollo/client';
import { getApolloClient } from '../../config/apollo/configureApollo';

export const SUBMIT_ANALYTIC_EVENTS_MUTATION = gql`
  mutation CONSOLE_SubmitAnalyticEvents($input: SubmitAnalyticEventsInput!) {
    result: submitAnalyticEvents(input: $input) {
      events {
        eventID
      }
      error {
        id
        message
        type
      }
    }
  }
`;

export async function submitToBackend(event: AnalyticEventInput): Promise<any> {
  try {
    const client = getApolloClient();

    await client.mutate<Data, Variables>({
      fetchPolicy: 'no-cache',
      mutation: SUBMIT_ANALYTIC_EVENTS_MUTATION,
      variables: {
        input: { events: [event] },
      },
    });
  } catch (error) {
    // do nothing
  }
}
