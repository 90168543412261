import { SagaIterator } from 'redux-saga';
import { toast } from 'react-toastify';
import { put, takeEvery, select } from 'redux-saga/effects';
import { CurrencyCode } from '../../graphql/globalTypes';
import { selectCartItems, selectCurrencyCode } from './selectors';
import { setItemInCartItemAllowed } from './actions';
import { SET_ITEMS_IN_CART, SetItemInCartItemAction } from './types';

export const sagas = [loginRequestsSaga];

function* loginRequestsSaga(): SagaIterator {
  yield takeEvery(SET_ITEMS_IN_CART, setItemInCartSaga);
}

function* setItemInCartSaga({
  payload,
}: SetItemInCartItemAction): SagaIterator {
  // verify currency
  const cartItems = yield select(selectCartItems);
  const prevCurrencyCode: CurrencyCode = yield select(selectCurrencyCode);
  const nextCurrencyCode = payload.currencyCode || CurrencyCode.USD;
  const areItemsInCart = cartItems.length > 0;

  if (areItemsInCart && prevCurrencyCode !== nextCurrencyCode) {
    const message =
      'Mixed currencies are currently not supported. Please purchase them separately.';
    toast.error(message, { toastId: message });
  } else {
    yield put(setItemInCartItemAllowed(payload));
  }
}
