export type AppState = {
  graphQLAPIEndpoint: string;
};

export const SET_GRAPHQL_API_ENDPOINT = 'SET_GRAPHQL_API_ENDPOINT';

export type SetGraphQLAPIEndpointPayload = {
  endpoint: string;
};

interface SetGraphQPApiEndpointAction {
  type: typeof SET_GRAPHQL_API_ENDPOINT;
  payload: SetGraphQLAPIEndpointPayload;
}

export type AppActionTypes = SetGraphQPApiEndpointAction;
