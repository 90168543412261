import { ApolloLink, Observable } from '@apollo/client';
import { selectGraphQLAPIEndpoint } from '../../../store/app/selectors';
import { getStore } from '../../../store';

// Function to create a link that fetches the endpoint from Redux
export const createEndpointLink = () =>
  new ApolloLink((operation, forward) => {
    // Get the current endpoint from the Redux store
    const currentEndpoint = selectGraphQLAPIEndpoint(getStore().getState());

    // Modify the context to include the new URI
    operation.setContext({
      uri: currentEndpoint,
    });

    return new Observable((observer) => {
      const subscription = forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      });

      return () => {
        subscription.unsubscribe();
      };
    });
  });
