import { ApolloProvider } from '@apollo/client';
import { ReactNode } from 'react';
import { useApolloClient } from '../../config/apollo/useApolloClient';
import { LoadingScreen } from '../../components/loading/LoadingScreen';
import { ErrorScreen } from '../../components/error/ErrorScreen';

interface Props {
  children: ReactNode;
}

export function ApolloCacheProvider({ children }: Props) {
  const { client, loading, error } = useApolloClient();

  if (loading) {
    return <LoadingScreen />;
  }

  if (client === null || error)
    return (
      <ErrorScreen
        error={error || Error('apollo client failed to initialize')}
      />
    );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
