import {
  ClearCartPayload,
  SetItemInCartItemPayload,
  SetCurrencyCodePayload,
  SetItemInCartItemAction,
  ClearCartAction,
  SET_ITEMS_IN_CART,
  CLEAR_CART,
  SET_CURRENCY_CODE,
  SetCurrencyCodeAction,
  SET_ITEMS_IN_CART_ALLOWED,
  SetItemInCartItemAllowedPayload,
  SetItemInCartItemAllowedAction,
  SetBundleItemInCartItemPayload,
  SetBundleItemInCartItemAction,
  SET_BUNDLE_ITEMS_IN_CART,
} from './types';

export function setItemInCartItem(
  payload: SetItemInCartItemPayload
): SetItemInCartItemAction {
  return {
    type: SET_ITEMS_IN_CART,
    payload,
  };
}

export function setBundleItemInCartItem(
  payload: SetBundleItemInCartItemPayload
): SetBundleItemInCartItemAction {
  return {
    type: SET_BUNDLE_ITEMS_IN_CART,
    payload,
  };
}

export function setItemInCartItemAllowed(
  payload: SetItemInCartItemAllowedPayload
): SetItemInCartItemAllowedAction {
  return {
    type: SET_ITEMS_IN_CART_ALLOWED,
    payload,
  };
}

export function clearCart(payload: ClearCartPayload): ClearCartAction {
  return {
    type: CLEAR_CART,
    payload,
  };
}

export function setCurrencyCode(
  payload: SetCurrencyCodePayload
): SetCurrencyCodeAction {
  return { type: SET_CURRENCY_CODE, payload };
}
