import { sessionStart } from '../features/analytics';
import { useBeforeFirstRender } from '../hooks/useBeforeFirstRender';

// create a sessionID
export const SessionEventHandler = () => {
  useBeforeFirstRender(() => {
    sessionStart();
  });

  return null;
};
