import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export function createDeviceID(): string {
  let deviceID;

  // check if localstorage is supported
  if (window.localStorage) {
    // get deviceID from localstorage
    deviceID = localStorage.getItem('deviceID');

    if (!deviceID) {
      // if deviceID isn't stored in localstorage,
      // generate a new one
      deviceID = uuidv4();

      // and set it in localstorage
      localStorage.setItem('deviceID', deviceID);
    }
  } else {
    // if localstorage isn't supported, use cookies.
    // check if a deviceID is stored in cookies
    deviceID = Cookies.get('deviceID');

    if (!deviceID) {
      // if not stored, generate a new one
      deviceID = uuidv4();

      // set it in cookies
      Cookies.set('deviceID', deviceID);
    }
  }

  return deviceID;
}
