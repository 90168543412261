import { createTheme, ThemeOptions } from '@material-ui/core';
import { COLORS } from './components/AppGlobalStyle';

const common: ThemeOptions = {
  typography: {
    h1: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 28,
      fontWeight: 400,
    },
    h2: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 24,
      fontWeight: 400,
    },
    h3: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 22,
      fontWeight: 400,
    },
    h4: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 20,
      fontWeight: 400,
    },
    h5: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 18,
      fontWeight: 400,
    },
    h6: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 16,
      fontWeight: 400,
    },
    body1: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 14,
      fontWeight: 400,
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: 10,
      },
    },
    MuiInput: {
      root: {
        fontFamily: 'Roboto Condensed, sans-serif',
      },
    },
    MuiListItemText: {
      root: {
        fontFamily: 'Roboto Condensed, sans-serif',
      },
    },
    MuiCardHeader: {
      title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
      },
    },
  },
};

export const BusinessTheme = createTheme({
  ...common,
  palette: {
    type: 'light',
    primary: {
      light: COLORS.grey,
      main: COLORS.grey,
      dark: COLORS.grey,
    },
    secondary: {
      light: COLORS.green,
      main: COLORS.green,
      dark: COLORS.green,
    },
  },
});

export const InternalTheme = createTheme({
  ...common,
  palette: {
    type: 'light',
    primary: {
      light: COLORS.pink,
      main: COLORS.pink,
      dark: COLORS.pink,
    },
    secondary: {
      light: COLORS.green,
      main: COLORS.green,
      dark: COLORS.green,
    },
  },
});
