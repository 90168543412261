import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Button } from '@material-ui/core';
import { Close as CloseIcon, Redeem as RedeemIcon } from '@material-ui/icons';
import { useEffect } from 'react';
import QRCode from 'qrcode.react';
import styled, { createGlobalStyle } from 'styled-components';
import { toast } from 'react-toastify';
import { captureEvent, DataKeys, EventNames } from '../../features/analytics';
import { getQRCodeLink } from '../../utils/getQRCodeLink';
import {
  CONSOLE_DistributeRedeemCode as Data,
  CONSOLE_DistributeRedeemCodeVariables as Variables,
} from '../../graphql/mutations/__generated__/CONSOLE_DistributeRedeemCode';
import { DISTRIBUTE_REDEEM_CODE } from '../../graphql/mutations/DistributeRedeemCode';
import DownloadActivationPDF from '../../components/DownloadActivationPDF';
import PDFActivation from '../../components/PDFActivation';
import useRouteChange from '../../hooks/useRouteChange';

interface Props {
  close: () => void;
  show: boolean;
  modalData: {
    redeemCode: string;
    distributedAt: string | null;
    appURL: string | null;
    client: {
      logoImage?: {
        uri: string;
      } | null;
    };
  };
}

// this is a trickery.
// basically, we make use of the modals feature, but instead of showing a modal,
// we mount a component which does not render unless in screen mode. only shows in printing mode.
// the component has a z-index of 2 as well.
// everything else is hidden using not-printable css class in printing mode. (including the toast)
export const PrintRedeemCode = ({
  close,
  // show,
  modalData,
}: Props) => {
  const { redeemCode, appURL, client } = modalData;

  let variables: Variables | null = null;

  const getVariables = () => variables;

  const [setDistributed, { loading }] = useMutation<Data, Variables>(
    DISTRIBUTE_REDEEM_CODE,
    {
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });
        toast.error(error.message);
      },
      onCompleted: ({ result }) => {
        const { distributed, error } = result;

        if (distributed) {
          toast.info('Code marked as distributed', {
            toastId: 'distributed',
          });
        } else {
          const errorMessage = error?.message || 'Unknown error';

          toast.error(errorMessage, {
            toastId: errorMessage,
          });
        }
      },
    }
  );

  // let distributedButtonCaption = 'Distribute code';

  // if (distributed) {
  //   distributedButtonCaption = 'Code has been distributed';
  // } else if (loading) {
  //   distributedButtonCaption = 'Distributing...';
  // }

  useEffect(
    () => {
      window.addEventListener('afterprint', (event) => {
        setDistributed({
          variables: {
            input: {
              redeemCode,
              distributed: true,
            },
          },
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useRouteChange(close);

  const print = () => {
    captureEvent({
      name: EventNames.PRINT_TICKET_ATTEMPTED,
      data: [
        {
          key: DataKeys.REDEEM_CODE,
          value: redeemCode,
        },
      ],
    });

    // open print dialog
    window.print();
  };

  return (
    <PrintContainer>
      <PrintStyle />

      <PrintableActivationCodeContainer className="printable" id="print-ticket">
        <Instructions redeemCode={redeemCode} appURL={appURL} />
      </PrintableActivationCodeContainer>

      <CloseIcon
        className="not-printable"
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
        onClick={close}
      />

      <DownloadActivationPDF
        document={PDFActivation}
        documentProps={{ redeemCode, appURL, client }}
        onDownloadSuccess={() => {
          // Mark the code as distributed after the PDF is downloaded
          setDistributed({
            variables: {
              input: {
                redeemCode,
                distributed: true,
              },
            },
          });
        }}
        filename={`Tour-Activation-Instructions-${redeemCode}.pdf`}
        className="not-printable"
        style={{ marginTop: '4mm' }}
      />

      <Button
        onClick={print}
        color="primary"
        autoFocus
        variant="outlined"
        size="large"
        className="not-printable"
        style={{ marginTop: '4mm' }}
        disabled={loading}
      >
        {loading ? 'Please wait...' : 'Print with a Ticket Printer'}
      </Button>

      {/* <Button
        onClick={() => {
          setDistributed({
            variables: {
              input: {
                redeemCode,
                distributed: true,
              },
            },
          });
        }}
        disabled={loading || distributed}
        color="primary"
        variant="contained"
        size="large"
        className="not-printable"
        style={{ marginTop: '4mm' }}
      >
        {distributedButtonCaption}
      </Button> */}

      <Button
        onClick={close}
        color="secondary"
        variant="contained"
        size="large"
        className="not-printable"
        style={{ marginTop: '4mm' }}
        disabled={loading}
      >
        Close
      </Button>
    </PrintContainer>
  );
};

interface InstructionsProps {
  redeemCode: string;
  appURL: string | null;
}

const Instructions = (props: InstructionsProps) => {
  const link = getQRCodeLink(props);

  return (
    <>
      <section>
        <p style={{ fontSize: '5mm' }}>
          <strong>Instructions:</strong>
        </p>

        <p>Method 1:</p>

        <table>
          <tbody>
            <tr>
              <td>1. Open the camera.</td>
            </tr>
            <tr>
              <td>2. Aim at QR code.</td>
            </tr>
            <tr>
              <td>3. Tap the link.</td>
            </tr>
          </tbody>
        </table>

        <a href={link}>
          <QRCodeContainer>
            <QRCode level="M" size={150} renderAs="svg" value={link} />
          </QRCodeContainer>
        </a>
      </section>
      <section>
        <p>Method 2:</p>

        <table>
          <tbody>
            <tr>
              <td>1. Visit:</td>
            </tr>

            <tr>
              <td>
                &nbsp;&nbsp;&nbsp;
                <strong>{props.appURL || 'ancient-world.co'}.</strong>
              </td>
            </tr>

            <tr>
              <td>
                2. Click the <RedeemIcon fontSize="small" /> icon.
              </td>
            </tr>

            <tr>
              <td>3. Sign up.</td>
            </tr>

            <tr>
              <td>4. Enter code:</td>
            </tr>

            <tr>
              <td>
                &nbsp;&nbsp;&nbsp;<strong>{props.redeemCode}</strong>
              </td>
            </tr>

            <tr>
              <td>5. Click REDEEM.</td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  );
};

const QRCodeContainer = styled.div`
  margin: 6mm auto 0;
  display: flex;
  justify-content: center;
`;

const PrintableActivationCodeContainer = styled.div`
  padding: 0 2mm;
`;

const PrintContainer = styled.div`
  z-index: 9999999999999999999999;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  right: 0;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media print {
    padding: 0;
    height: auto;
    align-items: flex-start;
  }

  @page {
    padding: 0;
    margin: 5mm 0 0 5mm;
    size: 55mm 140mm;
  }
`;

const PrintStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'courier', sans-serif;
  }

  p {
    padding: 0;
    margin-bottom: 2mm;
    font-size: 5mm;
  }

  td {
    padding: 0;
    margin-bottom: 2mm;
    font-size: 5mm;
  }

  section {
    page-break-inside: avoid;
    /* page-break-before: avoid;
    page-break-after: avoid; */
    margin-bottom: 6mm;
  }

  section:last-child {
    margin-bottom: 0;
  }
`;

// works well when done in plain html file
// when the dialog is removed, 2nd page is removed. but does not fill up.
// should not persist modal state!!!
