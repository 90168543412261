import { useEffect, useRef } from 'react';

// get the value in the previous render.
// useful for implementing conditional logic when a value changes.
export function usePrevious<T>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
