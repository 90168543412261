import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ProductSKU } from '../../graphql/globalTypes';
import {
  CONSOLE_DistributeAllCodesInDownloadClientRedemptionCodesDialog as Data,
  CONSOLE_DistributeAllCodesInDownloadClientRedemptionCodesDialogVariables as Variables,
} from './__generated__/CONSOLE_DistributeAllCodesInDownloadClientRedemptionCodesDialog';
import { DISTRIBUTE_ALL_CODES_MUTATION } from './DownloadClientRedemptionCodesCSV';
import {
  DownloadPDFInstructions,
  QRCodeComponent,
} from './DownloadPDFInstructions';
import { formatDateStandard } from '../../utils/formatDateStandard';

enum DistributedStatus {
  UNDISTRIBUTED = 'UNDISTRIBUTED',
  DISTRIBUTED = 'DISTRIBUTED',
  BOTH = 'BOTH',
}

type Purchase = {
  productSKU: ProductSKU;
  productID: string;
  redeemCode: string;
  redeemedAt: Date;
  distributedAt: Date | null;
};

interface Props {
  close: () => void;
  data: {
    purchases: Array<Purchase>;
    allTours: Array<{
      id: string;
      nameI18n: { en_US: string | null };
      internalReference: string;
    }>;
  };
}

export const DownloadClientRedemptionCodesPDF = ({
  close,
  data: { purchases, allTours },
}: Props) => {
  const [qrCodeBase64, setQrCodeBase64] = useState([]);
  // console.log(accessCodes, allTours);
  const [distributedStatus, setDistributedStatus] = useState<DistributedStatus>(
    DistributedStatus.UNDISTRIBUTED
  );
  const [distribute, setDistribute] = useState<boolean>(
    distributedStatus !== DistributedStatus.DISTRIBUTED
  );
  const [distributeCodes] = useMutation<Data, Variables>(
    DISTRIBUTE_ALL_CODES_MUTATION
  );

  const downloadPDF = async () => {
    try {
      if (distributedStatus !== DistributedStatus.DISTRIBUTED && distribute) {
        const products = getProducts(purchases);
        await distributeCodes({
          variables: {
            input: {
              notes: `Distributed when downloading the PDF file on ${formatDateStandard(
                new Date()
              )}`,
              products,
            },
          },
        });
      }

      close();
    } catch (error) {
      toast.error('An error occurred while generating PDF file', {
        toastId: 'redeem_codes_pdf_error',
        hideProgressBar: true,
        pauseOnHover: false,
      });
    }
  };

  const handleDistributedStatusChange = (event) => {
    setDistributedStatus(event.target.value);
  };

  const handleDistributeUndistributedCodesChange = (event) => {
    setDistribute(event.target.checked);
  };
  const rawData = getRelevantPurchases(purchases, distributedStatus);
  const relevantCodes = convertRawDataIntoRelevantCodes(rawData, allTours);
  const toPrintDocument = (
    <div>
      {relevantCodes.map(({ code, link }, index) => (
        <DownloadPDFInstructions
          key={index}
          index={index}
          token={code}
          qrCodeBase64={qrCodeBase64?.[index] || ''}
          appURL={link}
        />
      ))}
    </div>
  );
  return (
    <>
      {relevantCodes.map(({ code, link }, index) => {
        return (
          <QRCodeComponent
            key={index}
            index={index}
            token={code}
            setQrCodeBase64={setQrCodeBase64}
            appURL={link}
          />
        );
      })}
      <Dialog
        open
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Download Redeem Codes as a PDF File
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box>
              <Typography gutterBottom>
                If you want to feed the redeem codes into a separate CMS or
                simply to backup your codes, this feature will be helpful.
              </Typography>

              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Distributed status of the redeem codes to download"
                  value={distributedStatus}
                  onChange={handleDistributedStatusChange}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={DistributedStatus.UNDISTRIBUTED}
                    control={<Radio />}
                    label="Download only the undistributed redeem codes"
                  />
                  <FormControlLabel
                    value={DistributedStatus.DISTRIBUTED}
                    control={<Radio />}
                    label="Download only the distributed redeem codes"
                  />
                  <FormControlLabel
                    value={DistributedStatus.BOTH}
                    control={<Radio />}
                    label="Download all redeem codes"
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <Divider />

            <Box>
              <FormControlLabel
                disabled={distributedStatus === DistributedStatus.DISTRIBUTED}
                control={<Checkbox defaultChecked={distribute} />}
                label="Mark all undistributed codes as distributed"
                onChange={handleDistributeUndistributedCodesChange}
              />

              <Typography>
                Recommended to check this checkbox if you are uploading the
                codes to a CMS to prevent duplicates
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <PDFDownloadLink
            document={toPrintDocument}
            fileName={`AncientWorld_RedeemCodes_${format(
              new Date(),
              'yyyyMMdd'
            )}.pdf`}
          >
            {({ blob, url, loading, error }) => (
              <Button
                onClick={async () => {
                  await downloadPDF();
                }}
                color="primary"
                variant="outlined"
                autoFocus
                disabled={loading}
              >
                {loading ? 'Processing...' : 'Download PDF'}
              </Button>
            )}
          </PDFDownloadLink>
          <Button onClick={close} color="secondary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
function getRelevantPurchases(
  purchases: Array<any>,
  distributedStatus: DistributedStatus
) {
  if (distributedStatus === DistributedStatus.DISTRIBUTED) {
    return purchases.filter((p) => p.distributedAt);
  }

  if (distributedStatus === DistributedStatus.UNDISTRIBUTED) {
    return purchases.filter((p) => !p.distributedAt);
  }

  return purchases;
}

function getProducts(purchases: Array<Purchase>) {
  let productsMap: { [key: string]: string[] } = {};

  productsMap = purchases.reduce((accum, p) => {
    const currentProductIDs = accum[p.productSKU] || [];

    accum[p.productSKU] = [...currentProductIDs, p.productID];

    return accum;
  }, {});

  // The above reducer gives this
  // {
  //   productSKU1: [productID1, productID2],
  //   productSKU2: [productIDA, productIDB]
  // }

  const products = Object.keys(productsMap).reduce(
    (accum, productSKU) => [
      ...accum,
      ...productsMap[productSKU].map((productID) => ({
        productSKU,
        productID,
      })),
    ],
    []
  );

  // The above gives
  // [
  //   { productSKU: 'productSKU1', productID: 'productID1' },
  //   { productSKU: 'productSKU1', productID: 'productID2' },
  //   { productSKU: 'productSKU2', productID: 'productIDA' },
  //   { productSKU: 'productSKU2', productID: 'productIDB' },
  // ];

  return products;
}

type PDFRow = {
  tourname: string;
  code: string;
  redeemedAt: any;
};

function sortByTourname(a: PDFRow, b: PDFRow) {
  var nameA = a.tourname.toUpperCase(); // ignore upper and lowercase
  var nameB = b.tourname.toUpperCase(); // ignore upper and lowercase

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

function getProductLinkWithRCodeByInternalReference(internalReference: string) {
  switch (true) {
    //discover
    case internalReference === 'ancient_acropolis':
      return `https://discover.lithodomos.com/athenian-acropolis`;
    case internalReference === 'ancient_paestum':
      return `https://discover.lithodomos.com/paestum`;
    case internalReference === 'ancient_london':
      return `https://discover.lithodomos.com/london`;
    case internalReference === 'expedition_malaga':
      return `https://discover.lithodomos.com/malaga`;
    case internalReference === 'expedition_barcelona':
      return `https://discover.lithodomos.com/barcelona`;
    case internalReference === 'discover_medina':
      return `https://discover.lithodomos.com/medina-azahara`;
    //guide
    case internalReference?.includes('cambridge'):
      return `https://guide.lithodomos.com/cambridge`;
    case internalReference?.includes('fatima'):
      return `https://guide.lithodomos.com/cityrama_fatima`;
    case internalReference?.includes('hellenic'):
      return `https://guide.lithodomos.com/hellenicmuseum`;
    case internalReference?.includes('notfair'):
      return `https://guide.lithodomos.com/notfair`;
    case internalReference === 'ancient_ostia':
      return `https://guide.lithodomos.com/notfair`;
    //default
    default:
      return `https://ancient-world.co`;
  }
}
function convertRawDataIntoRelevantCodes(
  purchases: Array<Purchase>,
  allTours: Array<{
    id: string;
    nameI18n: { en_US: string | null };
    internalReference: string;
  }>
): Array<any> {
  const toursMap = allTours.reduce((accum, tour) => {
    const tourName = tour.nameI18n?.en_US;
    const internalReference = tour?.internalReference;
    if (tourName) {
      accum[tour.id] = { tourName, internalReference };
    }

    return accum;
  }, {});

  const rawData = purchases
    .map((p) => ({
      // link: getProductLinkByInternalReference(
      //   toursMap[p.productID]?.internalReference
      // ),
      link: getProductLinkWithRCodeByInternalReference(
        toursMap[p.productID]?.internalReference
      ),
      tourname: toursMap[p.productID].tourName || p.productID,
      code: p.redeemCode,
      redeemedAt: p.redeemedAt,
    }))
    .sort(sortByTourname);

  return rawData;
}
