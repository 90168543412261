import { createGlobalStyle } from 'styled-components';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '../../styles/photo-sphere-viewer.css';
import 'react-toastify/dist/ReactToastify.css';

export const SCREEN_WIDTH_BREAK_EXTRA_LARGE = 1100;
export const SCREEN_WIDTH_BREAK_LARGE = 960;
export const SCREEN_WIDTH_BREAK_MEDIUM = 800;
export const SCREEN_WIDTH_BREAK_SMALL = 676;
export const SCREEN_WIDTH_BREAK_EXTRA_SMALL = 500;
export const SCREEN_WIDTH_MAX = 1200;

export const HEADER_HEIGHT = 50;
export const HEADER_WIDTH = 240;

export const HEADER_BUTTON_SIZE = 18;

export const ICON_BUTTON_SIZE = 20;

export const COLORS = {
  black: '#000000',
  grey: '#1D1A1A',
  green: '#23808c',
  blue: '##0066FF',
  pink: '#da2f72',
  red: '#c41057',

  secondaryTextColor: '#777',
  tertiaryTextColor: '#fff',
  activeColor: 'rgb(224, 56, 133)',
  secondaryBackgroundColor: 'rgb(60, 217, 255)',
  tertiaryColor: 'rgba(93, 148, 255, 0.84)',
};

export const AppGlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Condensed Regular'), local('RobotoCondensed-Regular'), url("/fonts/RobotoCondensed-Regular.ttf") format('truetype');
  }

  * {
    font-family: "Roboto Condensed", -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif;
  }

  html {
    background-color: #fff !important;
    color: #000;
  }

  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #fff !important;
    -webkit-overflow-scrolling: touch;
  }

  #root {
    height: 100%;
  }

  @media print {   
    .printable {
      visibility: visible;
    }

    .not-printable {
      visibility: hidden;
    }
  }
`;
