import { useSelector } from 'react-redux';
import { withTestSiteProtection } from '../hocs/withTestSiteProtection';
import BusinessRoutes from '../routes/businessRoutes/BusinessRoutes';
import InternalRoutes from '../routes/internalRoutes/InternalRoutes';
import UnAuthedRoutes from '../routes/unauthedRoutes/UnAuthedRoutes';
import { isAuthenticated, isInternal } from '../store/auth/selectors';

const AppRoutesView = () => {
  // perhaps these should be passed as props
  const authed = useSelector(isAuthenticated);
  const internal = useSelector(isInternal);

  if (internal) {
    return <InternalRoutes />;
  } else if (authed) {
    return <BusinessRoutes />;
  }

  return <UnAuthedRoutes />;
};

// We do not want anyone to use a staging site unless you know how to access it.
// withTestSiteProtection HOC asks for a password to enter the staging site.
export const AppRoutes = withTestSiteProtection(AppRoutesView);
