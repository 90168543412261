export type AuthState = {
  userID: string | null;
  jwt: string | null;
  prevJWT: string | null;
};

export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNOUT = 'SIGNOUT';
export const LOG_IN_AS_CLIENT = 'LOG_IN_AS_CLIENT';

export type SignInSuccessPayload = {
  userID: string;
  jwt: string;
};

export type LogInAsClientPayload = string;
interface SignInSuccessAction {
  type: typeof SIGNIN_SUCCESS;
  payload: SignInSuccessPayload;
}

interface SignOutAction {
  type: typeof SIGNOUT;
}

interface LogInAsClientAction {
  type: typeof LOG_IN_AS_CLIENT;
  payload: LogInAsClientPayload;
}

export type AuthActionTypes =
  | SignInSuccessAction
  | SignOutAction
  | LogInAsClientAction;
