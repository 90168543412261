import QRCode from 'qrcode';
import * as Sentry from '@sentry/react';

export async function generateQRCode(text) {
  try {
    const qrImageUrl = await QRCode.toDataURL(text);
    return qrImageUrl;
  } catch (error) {
    Sentry.captureException(error);
  }

  return '';
}
