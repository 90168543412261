export enum EventNames {
  SESSION_START = 'SESSION_START',
  SESSION_END = 'SESSION_END',
  ACTION_BUTTON_PRESS = 'ACTION_BUTTON_PRESS',
  TOUR_START = 'TOUR_START',
  TOUR_END = 'TOUR_END',
  VIEWPOINT_START = 'VIEWPOINT_START',
  VIEWPOINT_END = 'VIEWPOINT_END',
  IMAGE_START = 'IMAGE_START',
  IMAGE_END = 'IMAGE_END',
  ROUTE_CHANGE = 'ROUTE_CHANGE',
  // Acquisition
  LOGGED_IN = 'LOGGED_IN',
  USER_REGISTERED = 'USER_REGISTERED',
  // Purchases
  PURCHASED_PRODUCT = 'PURCHASED_PRODUCT',
  ACTIVATED_PRODUCT = 'ACTIVATED_PRODUCT',
  ATTEMPTED_COUPON_CODE = 'ATTEMPTED_COUPON_CODE',
  NOT_ALLOWED_TO_CHECEKOUT = 'NOT_ALLOWED_TO_CHECEKOUT',
  REDEMPTION_CODE_EXTRACTED = 'REDEMPTION_CODE_EXTRACTED',
  // Ratings
  RATED_TOUR = 'RATED_TOUR',
  // Location tracking
  USER_POSITION = 'USER_POSITION',
  LOCATION_SHARING_START = 'LOCATION_SHARING_START',
  LOCATION_SHARING_END = 'LOCATION_SHARING_END',
  // Modals
  VIEW_MODAL = 'VIEW_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  // Tour plans
  CREATED_TOUR_PLAN = 'CREATED_TOUR_PLAN',
  // Affiliation
  AFFILIATE_CODE_EXTRACTED = 'AFFILIATE_CODE_EXTRACTED',
  // In tour
  PANORAMA_CLICK = 'PANORAMA_CLICK',
  PANORAMA_ZOOM = 'PANORAMA_ZOOM',
  PANORAMA_ROTATE = 'PANORAMA_ROTATE',
  // Video playback
  ITINERARY_VIDEO_PLAYED = 'ITINERARY_VIDEO_PLAYED',
  // Print ticket
  PRINT_TICKET_ATTEMPTED = 'PRINT_TICKET_ATTEMPTED',
  PRINT_TICKET_SUCCESSFUL = 'PRINT_TICKET_SUCCESSFUL',
  PRINT_TICKET_FAILED = 'PRINT_TICKET_FAILED',
}

export enum DataKeys {
  ASSET = 'Asset',

  // Buttons
  PAGE = 'page',
  BUTTON_NAME = 'ButtonName',
  ACTION = 'Action',

  // coords
  LATITUDE = 'Latitude',
  LONGITUDE = 'Longitude',

  RATING = 'Rating',

  // Tour and tour specific links
  TOUR_ID = 'tourID',

  // Tour
  TOUR_DURATION = 'tourDuration',
  VIEWPOINT_ID = 'viewpointID',
  VIEWPOINT_DURATION = 'viewpointDuration',
  VIEWPOINT_VIEW_MODE = 'viewpointViewMode',
  IMAGE_ID = 'imageID',
  IMAGE_DURATION = 'imageDuration',

  // Session Start
  DEVICE_TYPE = 'deviceType',
  DEVICE_MODEL = 'model',
  DEVICE_VENDOR = 'vendor',
  OS = 'os',
  OS_VERSION = 'osVersion',
  BROWSWE_NAME = 'browserName',
  BROWSWE_VERSION = 'browserVersion',

  // App store links
  APP = 'app',
  STORE = 'store',

  // Media slider
  AUDIO_POSITION = 'audioPosition',

  // Route change
  FROM = 'from',
  TO = 'to',

  // Modals
  MODAL = 'modal',

  // Auth
  LOGIN_METHOD = 'loginMethod',
  SIGNUP_METHOD = 'signUpMethod',

  // Purchases
  COUPON_CODE = 'couponCode',
  ORDER_ID = 'orderID',
  REDEEM_CODE = 'redeemCode',
  AFFILIATE_CODE = 'affiliateCode',

  // Panorama position
  PANO_LONGITUDE = 'panoLongitude',
  PANO_LATITUDE = 'panoLatitude',

  // Panorama zoom level
  PANO_ZOOM_LEVEL = 'panoZoomLevel',
}
