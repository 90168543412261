import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';

interface Props {
  close: () => void;
  isComplete: { canPublish: boolean; reasons: string[] | null };
}

export const ViewpointPublishingErrorsDialog = ({
  close,
  isComplete,
}: Props) => {
  return (
    <Dialog
      open
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {!isComplete.canPublish
          ? 'The viewpoint cannot be published due to the following issues:'
          : 'The viewpoint can be published, however it has the following issues:'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {(isComplete.reasons || []).map((reason, idx) => (
            <Typography key={idx} gutterBottom>
              {reason}
            </Typography>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" autoFocus variant="outlined">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
