import { Typography } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

type Props = {
  error: unknown;
};

function getMessage(error: unknown): unknown {
  if (typeof error === 'object' && error !== null && 'message' in error) {
    return error.message;
  }

  return 'Unknown error';
}

export const ErrorScreen = ({ error }: Props) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return <Typography color="error">Error {getMessage(error)}</Typography>;
};
