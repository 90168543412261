import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Link,
  Image,
  Font,
} from '@react-pdf/renderer';

Font.register({
  family: 'RobotoCondensed',
  src: 'https://gaia.lithodomos.com/f1123f4b3d926ac4f72cc8091a4b5d19.ttf',
});

const REDEEM_CODE_ICON_URL =
  'https://gaia.lithodomos.com/d1998b53d5cea9814dd2d79b64a58da5.png';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 20,
    fontFamily: 'RobotoCondensed',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 8,
    textDecoration: 'underline',
  },
  sectionTitle: {
    fontSize: 18,
    marginBottom: 8,
  },
  instruction: {
    fontSize: 12,
    marginBottom: 8,
  },
  centeredInstruction: {
    fontSize: 12,
    marginBottom: 8,
    textAlign: 'center',
  },
  alternationInstruction: {
    fontSize: 12,
    marginBottom: 8,
    marginLeft: 8,
  },
  section: {
    margin: 8,
    padding: 8,
  },
  hr: {
    height: 1,
    backgroundColor: 'black',
    width: '100%',
  },
  qrCodeImage: {
    margin: 'auto',
    width: 150,
    height: 150,
  },
  icon: {
    width: 16,
    height: 16,
  },
  centeredText: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  logo: {
    position: 'absolute',
    top: 10,
    left: 10,
    width: 120,
    height: 120,
  },
  logoImg: {
    width: 120,
    height: 120,
  },
  linkText: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 10,
  },
});

type Props = {
  activationUrl: string;
  redeemCode: string;
  qrCodeDataImgUrl: string;
  appURL: string;
  logoURL?: string;
};

// IMPORTANT!
// Ensure this is a stateless dumb component.
// Do any asynchonous preparations in the DownloadActivationPDF component.
const PDFActivation = ({
  activationUrl,
  qrCodeDataImgUrl,
  redeemCode,
  appURL = 'ancient-world.co',
  logoURL,
}: Props) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {logoURL && (
          <View style={styles.logo}>
            <Image src={logoURL} style={styles.logoImg} />
          </View>
        )}
        <View style={styles.section}>
          <Text style={styles.title}>How to activate a tour</Text>
          <Text style={styles.centeredInstruction}>
            Please follow the instructions below to activate your tour.
          </Text>
        </View>
        <View style={styles.section}>
          <Link src={activationUrl}>
            <Image style={styles.qrCodeImage} src={qrCodeDataImgUrl} />
          </Link>
          <Link src={activationUrl}>
            <Text style={styles.linkText}>{activationUrl}</Text>
          </Link>
          <View>
            <Text style={styles.centeredText}>SCAN / TAP / CLICK</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>
            Method 1: If you have the digital copy of this document:
          </Text>
          <Text style={styles.instruction}>
            Tap/click the QR code above. This will launch the tour in your
            default browser.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>
            Method 2: If you have a printed version of this document:
          </Text>
          <Text style={styles.instruction}>1. Open the camera.</Text>
          <Text style={styles.instruction}>2. Aim at QR code above.</Text>
          <Text style={styles.instruction}>
            3. Tap the link that pops up in the phone.
          </Text>
          <Text style={styles.instruction}>
            This will launch the tour in your default browser.
          </Text>
        </View>
        <View style={styles.hr}></View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Manual activation:</Text>
          <Text style={styles.instruction}>
            If the automatic activation does not work, you can manually activate
            the tour by following these steps:
          </Text>
          <Text style={styles.instruction}>
            1. Visit <Link src={appURL}>{appURL}</Link> in your browser.
          </Text>
          <Text style={styles.instruction}>
            2. Click the{' '}
            <Image style={styles.icon} src={REDEEM_CODE_ICON_URL} /> icon at the
            top right corner of the screen.
          </Text>
          <Text style={styles.instruction}>
            3. Log in or Sign up. You can continue as a guest if you do not wish
            to create an account.
          </Text>
          <Text style={styles.instruction}>
            4. Enter the code: {redeemCode} and press REDEEM.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDFActivation;
