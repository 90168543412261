import { applyMiddleware, createStore, compose, Store } from 'redux';
import * as Sentry from '@sentry/react';
import createSagaMiddleware from 'redux-saga';
import { Persistor, persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './rootReducer';
import { env } from '../config/env';
import { RootState, SagasContext } from './types';
import { rootSaga } from './rootSaga';
import { transforms } from './transforms';
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

let _store: Store<RootState>;
let _context: SagasContext;
let _persistor: Persistor;

export function createReduxStore(context: SagasContext) {
  if (_store) {
    throw new Error('Store is already created. Use getStore fn instead');
  }

  // Refer to: https://extension.remotedev.io/#usage
  const reduxDevToolsExtensionCompose =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  const composeEnhancers = reduxDevToolsExtensionCompose
    ? reduxDevToolsExtensionCompose({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

  _context = context;

  const sagaMiddleware = createSagaMiddleware({
    context: _context,
    onError: onSagaError,
  });

  const middleware = [sagaMiddleware].filter(Boolean);

  const persistConfig = {
    key: env.REDUX_PERSIST_KEY,
    version: env.REDUX_PERSIST_VERSION,
    debug: env.isDevelopment,
    storage,
    blacklist: ['scripts', 'modal'],
    transforms,
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const enhancer = composeEnhancers(applyMiddleware(...middleware));

  _store = createStore(persistedReducer, enhancer);

  _persistor = persistStore(_store);

  sagaMiddleware.run(rootSaga);

  return { store: _store, persistor: _persistor };
}

export function getStore() {
  if (!_store) {
    throw new Error('Store should be initialised before it can be used');
  }

  return _store;
}

function onSagaError(error: Error) {
  Sentry.captureException(error);
}
