import {
  ScriptsState,
  SET_SCRIPT_LOADING_STATUS,
  ScriptsActionTypes,
  ScriptLoadingStatus,
} from './types';

export const initialState: ScriptsState = {
  paypal: ScriptLoadingStatus.IDLE,
  googleMaps: ScriptLoadingStatus.IDLE,
};

export function scripts(
  state: ScriptsState = initialState,
  { type, payload }: ScriptsActionTypes
) {
  switch (type) {
    case SET_SCRIPT_LOADING_STATUS:
      return {
        ...state,
        [payload.name]: payload.status,
      };
    default:
      return state;
  }
}
