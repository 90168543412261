import qs from 'query-string';

interface Args {
  appURL: string | null;
  redeemCode: string;
}

export function getQRCodeLink({ redeemCode, appURL }: Args): string {
  const url = appURL || 'https://ancient-world.co';

  return qs.stringifyUrl({
    url,
    query: { rcode: redeemCode },
  });
}
