import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { signOut } from '../../store/auth/actions';

interface Props {
  close: () => void;
}

export const LogoutDialog = ({ close }: Props) => {
  const dispatch = useDispatch();

  return (
    <Dialog
      open
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Log Out</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to log out from this session?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(signOut());
            close();
          }}
          color="primary"
          variant="outlined"
          autoFocus
        >
          Yes
        </Button>
        <Button onClick={close} color="secondary" variant="outlined">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
