import { useEffect, useState } from 'react';
import { ApolloClient } from '@apollo/client';
import { configureApollo } from './configureApollo';

interface State {
  client: null | ApolloClient<any>;
  loading: boolean;
  error: Error | null;
}

export const useApolloClient = () => {
  const [state, setState] = useState<State>({
    loading: true,
    error: null,
    client: null,
  });
  const client = null;

  useEffect(() => {
    const createApolloClient = async () => {
      setState({ loading: true, error: null, client });
      try {
        const client = await configureApollo();

        setState({ loading: false, error: null, client });
      } catch (error) {
        setState({ loading: false, error: error, client: null });
      }
    };
    createApolloClient();
  }, [client]);

  return state;
};
