import { format } from 'date-fns';

const DEFAULT_FORMAT_STRING = 'yyyy-MM-dd';

// This function formats a date object or string into a human-readable string.
export function formatDateStandard(
  date: Date | string,
  formatString?: string
): string {
  const formatStringSafe = formatString || DEFAULT_FORMAT_STRING;

  if (typeof date === 'string') {
    return format(new Date(date), formatStringSafe);
  }

  return format(date, formatStringSafe);
}
