import { gql } from '@apollo/client';

export const DISTRIBUTE_ACCESS_CODE = gql`
  mutation CONSOLE_DistributeAccessCode(
    $input: SetAccessCodeDistributedInput!
  ) {
    result: setAccessCodeDistributed(input: $input) {
      distributed
      error {
        message
      }
    }
  }
`;
