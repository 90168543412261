export enum ModalType {
  NONE = 'NONE',

  PRINT_REDEEM_CODE = 'PRINT_REDEEM_CODE',
  PRINT_BUNDLE_REDEEM_CODE = 'PRINT_BUNDLE_REDEEM_CODE',
  LOGOUT = 'LOGOUT',
  VIEPWOINT_PUBLISH_ERRORS = 'VIEPWOINT_PUBLISH_ERRORS',
  DOWNLOAD_CLIENT_REDEMPTION_CODES_CSV = 'DOWNLOAD_CLIENT_REDEMPTION_CODES_CSV',
  DOWNLOAD_CLIENT_REDEMPTION_CODES_PDF = 'DOWNLOAD_CLIENT_REDEMPTION_CODES_PDF',
  STOP_PUBLISH_ERRORS = 'STOP_PUBLISH_ERRORS',
  DOWNLOAD_CLIENT_BUNDLE_REDEMPTION_CODES_PDF = 'DOWNLOAD_CLIENT_BUNDLE_REDEMPTION_CODES_PDF',
  DOWNLOAD_CLIENT_BUNDLE_REDEMPTION_CODES_CSV = 'DOWNLOAD_CLIENT_BUNDLE_REDEMPTION_CODES_CSV',
}

export type ModalState = {
  modalType: ModalType;
  modalData: any;
};

export const SET_MODAL_TYPE = 'SET_MODAL_TYPE';

export type SetModalTypePayload = {
  modalType: ModalType;
  modalData?: any;
};

interface SetModalLoadingStatusAction {
  type: typeof SET_MODAL_TYPE;
  payload: SetModalTypePayload;
}

export type ModalActionTypes = SetModalLoadingStatusAction;
