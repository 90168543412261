import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePrevious } from './usePrevious';

function useRouteChange(onRouteChange: () => void): void {
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const prevPathname = usePrevious(pathname);

  useEffect(() => {
    // Function to handle back navigation attempts specifically for modals
    const handleBackNavigation = () => {
      // Call the closeModal or onRouteChange function to handle the back button click
      onRouteChange();

      // Use history.go(1) to cancel the back navigation without altering the history stack adversely
      history.go(1);
    };

    // @ts-expect-error - history block function TransitionPromptHook has incorrect return type
    const unblock = history.block((_newLocation, action) => {
      if (action === 'POP') {
        handleBackNavigation();
        return false; // This is intended to block the navigation, but we immediately counter it with history.go(1)
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [history, onRouteChange]);

  useEffect(() => {
    if (pathname !== prevPathname && prevPathname !== undefined) {
      onRouteChange();
    }
  }, [pathname, prevPathname, onRouteChange]);
}

export default useRouteChange;
